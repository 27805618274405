<template>
  <div>
    <!-- note-area -->
    <div class="note-area">
      <div class="accordion" id="accordionExample5">
        <div class="card">
          <div class="card-header" id="headingOne5">
            <div class="mb-0">
              <button
                class="btn btn-link"
                type="button"
                data-toggle="collapse"
                data-target="#collapseOne5"
              >
                <div class="detais-title">
                  <h3>
                    <img
                      class="img-fluid arrow-down"
                      src="../../assets/img/arrow-down.svg"
                      alt=""
                    />
                    Notes
                  </h3>
                </div>
              </button>
            </div>
          </div>

          <div
            id="collapseOne5"
            class="collapse show"
            aria-labelledby="headingOne5"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <div class="form-group" style="position: relative">
                <textarea
                  v-model="note"
                  class="form-control"
                  style="padding-right: 38px"
                  placeholder="Type here..."
                >
                </textarea>
                <img
                  @click="addNote"
                  style="
                    position: absolute;
                    bottom: 5px;
                    cursor: pointer;
                    right: 15px;
                    left: inherit;
                  "
                  class="img-fluid send"
                  src="../../assets/img/sendmsg.svg"
                  alt=""
                />
              </div>
              <p class="not-found" v-if="notes.length == 0">No Note Found.</p>
              <div v-else class="panel-group" id="accordion">
                <simplebar class="all-activity">
                  <div
                    class="panel panel-default"
                    v-for="(nt, i) in notes"
                    :key="nt.al_id"
                  >
                    <!-- v-if="activity.al_type < 3" -->
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a class="p-0" v-on:click="activityDetail(nt, i)">
                          <div class="activity">
                            <div class="activity-text">
                              <p>{{ nt.no_details | parseString }}</p>
                            </div>

                            <div class="Activity-time">
                              <p>
                                {{ nt.created }}
                              </p>
                            </div>
                          </div>
                        </a>
                      </h4>
                    </div>
                    <div :id="'note' + i" class="panel-collapse collapse">
                      <div class="panel-body">
                        <div class="row">
                          <div
                            class="col-md-6 detail-activity"
                            v-if="note_detail.created"
                          >
                            <div>Date:</div>
                            <div>{{ note_detail.created }}</div>
                          </div>
                          <div
                            class="col-md-6 detail-activity"
                            v-if="note_detail.time"
                          >
                            <div>Time:</div>
                            <div>{{ note_detail.time }}</div>
                          </div>
                          <div
                            class="col-md-6 detail-activity"
                            v-if="note_detail.name"
                          >
                            <div>By:</div>
                            <div>{{ note_detail.name }}</div>
                          </div>
                          <div
                            class="col-md-12 detail-activity"
                            v-if="note_detail.no_details"
                          >
                            <div>Description:</div>
                            <p v-html="note_detail.no_details"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </simplebar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import $ from "jquery";
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
export default {
  name: "Notes",
  data() {
    return {
      note: "",
      notes: [],
      note_detail: {},
      atag: "",
    };
  },
  props: ["lead_detail"],
  components: {
    simplebar,
  },
  created() {
    let vm = this;
    this.getNotes(this.$route.params.id);
    // if(this.$storage.get('auth').user.user_type == 2){
    //     this.getNotes(0)
    // }else if(this.$storage.get('auth').user.user_type == 1){
    //     this.getNotes(this.$route.params.id)
    // }
    this.$root.$on("action", function (action) {
      if (action == "new_note") {
        vm.getNotes(this.$route.params.id);
      }
    });
  },
  methods: {
    activityDetail(activity, i) {
      if ($("#note" + i).hasClass("show")) {
        $("#note" + i).removeClass("show");
      } else {
        $(".panel-collapse").removeClass("show");
        $("#note" + i).addClass("show");

        axios
          .get("/note/detail/" + activity.no_id, {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          })
          .then((res) => {
            this.note_detail = {};
            if (res.data.success) {
              this.note_detail = res.data.success;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      //}
    },
    addNote() {
      if (
        this.note != "" &&
        this.note != undefined &&
        this.note != "undefined" &&
        this.note != null
      ) {
        var param = {
          note: this.note,
          lead_id: this.$route.params.id,
          page: this.$route.name,
        };

        // if(this.$storage.get('auth').user.user_type == 2){
        //     param = {note:this.note,page:this.$route.name}
        // }else if(this.$storage.get('auth').user.user_type == 1){
        //     param = {note:this.note,lead_id:this.$route.params.id,page:this.$route.name}
        // }

        axios
          .post("add_note", param, {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
              "X-Requested-With": "XMLHttpRequest",
            },
          })
          .then((res) => {
            if (res.data.success) {
              this.notes.unshift(res.data.success);
              this.note = "";
              if (this.$storage.get("auth").user.user_type == 1) {
                this.$root.$emit("action", "new_activity");
              }
              this.$root.$emit(
                "action",
                "update_touch_status",
                res.data.lead_touch_resp_time
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getNotes(id) {
      axios
        .get("notes/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            const data = res.data;
            this.notes = data.success;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.activity-icon {
  min-height: 24px !important;
}

.detail-activity div {
  color: #6d778e;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  padding: 5px;
  float: left;
}
.detail-activity div:last-child {
  color: #343945;
}
.detail-activity div:first-child {
  min-width: 80px;
}
.Activity-time {
  width: 43%;
}
</style>