<template>
  <!-- appointments -->
  <div class="appointments">
    <div class="accordion" id="accordionExample3">
      <div class="card">
        <!-- <div class="card-header" id="headingOne3">
                    <h2 class="mb-0">
                    <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne3">
                        <div class="detais-title">
                        <h3><img class="img-fluid arrow-down" src="../../assets/img/arrow-down.svg" alt="">Appointments <a class="add-appoi add-appi" href="#"  v-on:click.stop.prevent="toggleAddAppointment('show')"><img class="img-fluid" src="../../assets/img/24. Close.svg" alt=""> add</a></h3>
                        </div>
                        <p>{{aptDate}} <img class="img-fluid calenders" src="../../assets/img/Vector (4).svg" alt=""></p>
                    </button>
                    </h2>
                </div> -->
        <div class="card-header" id="headingOne3">
          <h2 class="mb-0">
            <button
              class="btn btn-link"
              type="button"
              data-toggle="collapse"
              data-target="#collapseOne3"
            >
              <div class="detais-title">
                <h3>
                  <img
                    class="img-fluid arrow-down"
                    src="../../assets/img/arrow-down.svg"
                    alt=""
                  />Appointments
                  <a
                    v-if="$route.name != 'Dashboard'"
                    class="add-appoi add-appi"
                    href="#"
                    v-on:click.stop.prevent="toggleAddAppointment('show')"
                    ><img
                      class="img-fluid"
                      src="../../assets/img/24. Close.svg"
                      alt=""
                    />
                    add</a
                  >
                </h3>
              </div>
            </button>
            <!-- @update="funnelReport('custom')" -->
            <!-- <p class="single_date_chooser">
                            <date-range-picker :locale-data="{ format: 'yyyy-mm-dd' }"  v-model="aptDate" :single-date-picker="true" :show-dropdowns="true" :auto-apply="true" :ranges="false" @update="getAppointments('date')"></date-range-picker>
                            <span>{{aptDate.startDate | formatDate('MMMM DD, dddd')}} <img class="img-fluid calenders" src="../../assets/img/calendar.svg" alt=""></span>
                        </p> -->
          </h2>
        </div>

        <div
          id="collapseOne3"
          :class="lAppointments.length > 0 ? 'collapse show' : 'collapse'"
          aria-labelledby="headingOne3"
          data-parent="#accordionExample3"
        >
          <div class="card-body">
            <simplebar class="active-meeting" id="myElement2">
              <!-- active-meeting -->
              <div class="media" v-for="apt in lAppointments" :key="apt.ap_id">
                <p class="mr-3">{{ apt.ap_date_time | formatDate("MM/DD") }}</p>
                <!-- :class="aptColors[Math.floor(Math.random()*(3-1+1)+1)]" HH:mm -->
                <!-- <div class="media-body" :class="apt.time_status == 'today' ? aptColors[0]: apt.time_status == 'past' ? aptColors[1]:aptColors[2]"> -->
                <div
                  class="media-body"
                  :class="
                    apt.ap_status == 2
                      ? aptColors[1]
                      : apt.ap_status == 1
                      ? aptColors[2]
                      : apt.time_status == 'past'
                      ? aptColors[1]
                      : aptColors[0]
                  "
                >
                  <!-- <h5 class="mt-0">{{apt.ap_description}}</h5> -->
                  <h5 class="mt-0">
                    {{ apt.c_first_name }} {{ apt.c_last_name }}
                  </h5>
                  <p v-if="$storage.get('auth').user.user_type == 1">
                    Assigned to: {{ apt.name }}
                  </p>
                  <p>Description: {{ apt.ap_description }}</p>
                  <p>{{ apt.ap_date_time | formatDate("hh:mm A") }}</p>
                  <div class="appo-menu" :id="'mainAppt' + apt.ap_id">
                    <ul v-if="$route.name != 'Dashboard'">
                      <!-- <li>
                                                <a class="deks" href="#"><img class="img-fluid" src="../../assets/img/user-profile-checkmark.svg" alt=""></a>
                                            </li>
                                            <li>
                                                <a class="deks" href="#"><img class="img-fluid" src="../../assets/img/setting.svg" alt=""></a>
                                            </li>
                                            <li>
                                                <a class="deks" href="#"><img class="img-fluid" src="../../assets/img/desking.svg" alt=""></a>
                                            </li>
                                            <li>
                                                <a class="hands" href="#"><img class="img-fluid" src="../../assets/img/hand-stop.svg" alt=""> <span>2</span></a>
                                            </li> -->
                      <li>
                        <a
                          data-toggle="tooltip"
                          title="Confirmed"
                          :id="'comp' + apt.ap_id"
                          class="okay"
                          v-on:click.stop.prevent="
                            updateAptStatus(apt.ap_id, 3, apt.time_status)
                          "
                          :class="apt.ap_status == 3 ? 'okay-bg' : ''"
                          href="#"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                          >
                            <g clip-path="url(#clip0)">
                              <path
                                d="M0.769569 4.64628C0.574307 4.45102 0.257724 4.45102 0.0624622 4.64628C-0.1328 4.84155 -0.1328 5.15813 0.0624622 5.35339L0.769569 4.64628ZM2.91602 7.49984L2.56246 7.85339C2.75772 8.04865 3.07431 8.04865 3.26957 7.85339L2.91602 7.49984ZM7.8529 3.27006C8.04816 3.0748 8.04816 2.75821 7.8529 2.56295C7.65764 2.36769 7.34106 2.36769 7.14579 2.56295L7.8529 3.27006ZM2.8529 4.64628C2.65764 4.45102 2.34106 4.45102 2.1458 4.64628C1.95053 4.84155 1.95053 5.15813 2.1458 5.35339L2.8529 4.64628ZM4.6458 7.14628C4.45053 7.34155 4.45053 7.65813 4.6458 7.85339C4.84106 8.04865 5.15764 8.04865 5.3529 7.85339L4.6458 7.14628ZM9.93623 3.27006C10.1315 3.0748 10.1315 2.75821 9.93623 2.56295C9.74097 2.36769 9.42439 2.36769 9.22913 2.56295L9.93623 3.27006ZM0.0624622 5.35339L2.56246 7.85339L3.26957 7.14628L0.769569 4.64628L0.0624622 5.35339ZM5.3529 7.85339L9.93623 3.27006L9.22913 2.56295L4.6458 7.14628L5.3529 7.85339ZM3.26957 7.85339L4.31124 6.81172L3.60413 6.10462L2.56246 7.14628L3.26957 7.85339ZM4.31124 6.81172L7.8529 3.27006L7.14579 2.56295L3.60413 6.10462L4.31124 6.81172ZM2.1458 5.35339L3.60413 6.81172L4.31124 6.10462L2.8529 4.64628L2.1458 5.35339Z"
                                fill="#3151A1"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0">
                                <rect width="10" height="10" fill="white" />
                              </clipPath>
                            </defs></svg
                        ></a>
                      </li>
                      <li v-if="apt.ap_status == '0'">
                        <a
                          data-toggle="tooltip"
                          title="Reschedule"
                          href="#"
                          v-on:click.stop.prevent="reScheduleApt(apt)"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                          >
                            <g clip-path="url(#clip0)">
                              <path
                                d="M3.41634 1.25C3.41634 0.973858 3.19248 0.75 2.91634 0.75C2.6402 0.75 2.41634 0.973858 2.41634 1.25H3.41634ZM7.58301 1.25C7.58301 0.973858 7.35915 0.75 7.08301 0.75C6.80687 0.75 6.58301 0.973858 6.58301 1.25H7.58301ZM1.66634 2.58333H2.91634V1.58333H1.66634V2.58333ZM2.91634 2.58333H7.08301V1.58333H2.91634V2.58333ZM7.08301 2.58333H8.33301V1.58333H7.08301V2.58333ZM8.33301 9.08333H1.66634V10.0833H8.33301V9.08333ZM1.33301 8.75V4.16667H0.333008V8.75H1.33301ZM1.33301 4.16667V2.91667H0.333008V4.16667H1.33301ZM8.66634 2.91667V4.16667H9.66634V2.91667H8.66634ZM8.66634 4.16667V8.75H9.66634V4.16667H8.66634ZM0.833008 4.66667H9.16634V3.66667H0.833008V4.66667ZM3.41634 2.08333V1.25H2.41634V2.08333H3.41634ZM7.58301 2.08333V1.25H6.58301V2.08333H7.58301ZM1.66634 9.08333C1.48225 9.08333 1.33301 8.93409 1.33301 8.75H0.333008C0.333008 9.48638 0.929962 10.0833 1.66634 10.0833V9.08333ZM8.33301 10.0833C9.06939 10.0833 9.66634 9.48638 9.66634 8.75H8.66634C8.66634 8.9341 8.5171 9.08333 8.33301 9.08333V10.0833ZM8.33301 2.58333C8.5171 2.58333 8.66634 2.73257 8.66634 2.91667H9.66634C9.66634 2.18029 9.06939 1.58333 8.33301 1.58333V2.58333ZM1.66634 1.58333C0.929962 1.58333 0.333008 2.18029 0.333008 2.91667H1.33301C1.33301 2.73257 1.48225 2.58333 1.66634 2.58333V1.58333Z"
                                fill="#3151A1"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0">
                                <rect width="10" height="10" fill="white" />
                              </clipPath>
                            </defs></svg
                        ></a>
                      </li>

                      <li>
                        <a
                          data-toggle="tooltip"
                          title="Shown"
                          :id="'show' + apt.ap_id"
                          class="okay"
                          v-on:click.stop.prevent="
                            updateAptStatus(apt.ap_id, 1, apt.time_status)
                          "
                          :class="apt.ap_status == 1 ? 'okay-bg' : ''"
                          href="#"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="15"
                            viewBox="0 0 34 47"
                            fill="none"
                          >
                            <path
                              d="M18.3125 9.40841C20.595 9.40841 22.4454 7.52612 22.4454 5.2042C22.4454 2.88229 20.595 1 18.3125 1C16.03 1 14.1797 2.88229 14.1797 5.2042C14.1797 7.52612 16.03 9.40841 18.3125 9.40841Z"
                              stroke="#A2A8B8"
                              stroke-miterlimit="10"
                            />
                            <path
                              d="M16.6154 10.9098C12.4678 11.9008 8.54894 13.1696 5.84783 14.3633C5.35337 14.581 4.94746 14.9639 4.70392 15.4519L1.16886 22.5465C0.807241 23.2747 1.0434 24.1606 1.71499 24.596C2.49728 25.1065 3.53787 24.8062 3.95115 23.9579L7.16886 17.2912L11.6264 15.8498C11.7297 15.8198 11.8405 15.8198 11.929 15.8648C12.0914 15.9474 12.1726 16.1201 12.143 16.2927L8.822 34.8813C8.75558 35.2567 8.59322 35.6095 8.35706 35.9098L2.9327 42.6516C2.42348 43.2822 2.3792 44.1831 2.82938 44.8588C3.49359 45.8723 4.92532 45.9774 5.72237 45.0615L11.8035 38.087C12.1061 37.7417 12.298 37.3137 12.3497 36.8558L13.0286 31.06C13.0803 30.5945 13.619 30.3768 13.9733 30.6771L18.1947 34.2582C18.6523 34.6411 18.9327 35.1966 18.9844 35.7972L19.6928 44.063C19.7814 45.1216 20.7556 45.8648 21.7814 45.6621C22.6301 45.4894 23.2353 44.7161 23.1984 43.8378L22.7777 34.2207C22.7556 33.6201 22.4973 33.0495 22.0619 32.6366C22.0619 32.6366 16.3423 28.1996 16.8441 27.569C18.3202 25.6921 18.7334 19.6336 18.7334 19.6336C18.8294 19.2657 19.3091 19.1831 19.5157 19.4984L21.8847 22.9969C22.2464 23.5225 22.8072 23.8753 23.4272 23.9579L31.0139 24.9489C31.9142 25.069 32.7039 24.3558 32.7039 23.4324C32.7039 22.6966 32.1873 22.066 31.4788 21.9309L24.6523 20.6396C24.1283 20.542 23.6633 20.2116 23.4124 19.7387C21.191 15.4894 19.1246 10.3092 16.6154 10.9098Z"
                              stroke="#A2A8B8"
                              stroke-miterlimit="10"
                            />
                          </svg>
                        </a>
                      </li>

                      <li>
                        <a
                          data-toggle="tooltip"
                          title="Missed"
                          :id="'miss' + apt.ap_id"
                          v-on:click.stop.prevent="
                            updateAptStatus(apt.ap_id, 2, apt.time_status)
                          "
                          :class="apt.ap_status == 2 ? 'notok-bg' : ''"
                          class="notok"
                          href="#"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                          >
                            <path
                              d="M7.53033 1.53033C7.82322 1.23744 7.82322 0.762563 7.53033 0.46967C7.23744 0.176777 6.76256 0.176777 6.46967 0.46967L7.53033 1.53033ZM0.46967 6.46967C0.176777 6.76256 0.176777 7.23744 0.46967 7.53033C0.762563 7.82322 1.23744 7.82322 1.53033 7.53033L0.46967 6.46967ZM1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L1.53033 0.46967ZM6.46967 7.53033C6.76256 7.82322 7.23744 7.82322 7.53033 7.53033C7.82322 7.23744 7.82322 6.76256 7.53033 6.46967L6.46967 7.53033ZM6.46967 0.46967L0.46967 6.46967L1.53033 7.53033L7.53033 1.53033L6.46967 0.46967ZM0.46967 1.53033L6.46967 7.53033L7.53033 6.46967L1.53033 0.46967L0.46967 1.53033Z"
                              fill="#3151A1"
                            /></svg
                        ></a>
                      </li>
                      <li v-if="apt.ap_status == '0'">
                        <a
                          data-toggle="tooltip"
                          title="Delete"
                          :id="'delete' + apt.ap_id"
                          v-on:click.stop.prevent="deleteAppt(apt.ap_id)"
                          href="#"
                          ><img
                            style="width: 11px; margin-top: 5px"
                            src="../../assets/img/Delete.svg"
                        /></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="media not-found" v-if="lAppointments.length == 0">
                No Appointment Found.
              </div>
              <!-- active-meeting simplebar -->
            </simplebar>
          </div>
        </div>
      </div>
    </div>

    <!-- add-appointments -->
    <div class="add-appointments add-appointment">
      <div class="modal-header">
        <h5 class="modal-title">Add Appointment</h5>
        <a
          v-if="this.$store.state.contacted == false"
          class="close hide-appi"
          data-dismiss="modal"
          aria-label="Close"
          v-on:click="toggleAddAppointment('hide')"
        >
          <span aria-hidden="true"
            ><img
              class="img-fluid"
              src="../../assets/img/23. Closex.svg"
              alt=""
          /></span>
        </a>
      </div>
      <form v-on:submit.prevent="addNewAppointment">
        <div class="add-body">
          <div class="form-group">
            <label for="inputAddress">Date</label>
            <date-range-picker
              @update="getApptsForAddNew($route.params.id)"
              :min-date="yesterdayDate"
              :locale-data="{ format: 'mmmm dd, yyyy' }"
              v-model="addApt.ufDate"
              :single-date-picker="true"
              :show-dropdowns="true"
              :auto-apply="true"
              :ranges="false"
            ></date-range-picker>
            <!-- <input type="date" class="form-control" placeholder="Pick your date" v-model="addApt.date"> -->
            <div
              class="validate-error-message"
              v-if="$vd.addApt.ufDate.$hasError === true"
            >
              {{ $vd.addApt.$errors.ufDate[0] }}
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>Time</label>
              <vue-timepicker
                autocomplete="on"
                v-model="addApt.time"
                format="h:mm A"
                :minute-interval="5"
                placeholder="hh:mm a"
              ></vue-timepicker>
            </div>
            <div
              class="validate-error-message"
              v-if="$vd.addApt.time.$hasError === true"
            >
              {{ $vd.addApt.$errors.time[0] }}
            </div>
            <!-- <div class="form-group col-md-4">
                            <label for="inputCity">Time</label>
                            <select class="form-control" v-model="addApt.tHour">
                                <option value="">hours</option>
                                <option v-for="(n, index) in 12" :key="index" v-bind:value="n">{{n}}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputState"></label>
                            <select class="form-control" v-model="addApt.tMin">
                                <option value="">minutes</option>
                                <option v-for="(n, index) in 60" :key="index" v-bind:value="n-1 | numberStep('2')">{{n-1 | numberStep('2')}}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputZip"></label>
                            <select class="form-control" v-model="addApt.tDtype">
                                <option value="am">am</option>
                                <option value="pm">pm</option>
                            </select>
                        </div> -->
            <!-- <div class="validate-error-message" v-if="$vd.addApt.tHour.$hasError === true || $vd.addApt.tMin.$hasError === true || $vd.addApt.tDtype.$hasError === true">Pleae enter correct time</div> -->
          </div>
          <div
            class="form-group"
            v-if="$storage.get('auth').user.user_type == 1"
          >
            <label for="inputAddress2">Assign to</label>
            <select
              class="form-control"
              v-model="addApt.assignTo"
              v-on:change="getApptsForAddNew($route.params.id)"
            >
              <option
                v-for="emp in employeesList"
                :key="emp.id"
                :value="emp.id"
              >
                {{ emp.name }}
              </option>
              <!-- <option>Brandon Wilson</option>
                            <option>Brandon Wilson</option> -->
            </select>
            <!-- <div class="validate-error-message" v-if="$vd.addApt.assignTo.$hasError === true">{{$vd.addApt.$errors.assignTo[0]}}</div> -->
          </div>
          <div class="form-group">
            <label for="inputAddress2"
              >Description
              <!--<span>(optional)</span>--></label
            >
            <textarea
              class="form-control"
              v-model="addApt.desc"
              v-on:keyup="liveCountDown"
              rows="5"
            ></textarea>
            <p>
              Total Remaining:
              <span v-bind:class="{ 'text-danger': generateErr }">{{
                totalRemainCount
              }}</span>
            </p>
            <div
              class="validate-error-message"
              v-if="$vd.addApt.desc.$hasError === true"
            >
              {{ $vd.addApt.$errors.desc[0] }}
            </div>
          </div>
          <simplebar class="add-apt-scrollbar" id="myElement3">
            <div class="media" v-for="apt in addAppts" :key="apt.ap_id">
              <p class="mr-3">{{ apt.ap_date_time | formatDate("MM/DD") }}</p>
              <!-- <div class="media-body" :class="apt.time_status == 'today' ? aptColors[0]: apt.time_status == 'past' ? aptColors[1]:aptColors[2]"> -->
              <div
                class="media-body"
                :class="
                  apt.ap_status == 2
                    ? aptColors[1]
                    : apt.ap_status == 1
                    ? aptColors[2]
                    : apt.time_status == 'past'
                    ? aptColors[1]
                    : aptColors[0]
                "
              >
                <h5 class="mt-0">
                  {{ apt.c_first_name }} {{ apt.c_last_name }}
                </h5>
                <!-- <h5 class="mt-0">{{apt.ap_description}}</h5> -->
                <p v-if="$storage.get('auth').user.user_type == 1">
                  Assigned to: {{ apt.name }}
                </p>
                <p>{{ apt.ap_date_time | formatDate("hh:mm A") }}</p>
              </div>
            </div>
          </simplebar>
        </div>

        <div class="modal-footer app-footer">
          <a
            v-if="this.$store.state.contacted == false"
            href="#"
            class="add-btn"
            v-on:click="toggleAddAppointment('hide')"
          >
            Back</a
          >

          <button :disabled="appointmentLoader" type="submit" class="rem-btn">
            <span v-if="appointmentLoader"
              ><span class="spinner-grow spinner-grow-sm"></span>
              Loading...</span
            >
            <span v-if="!appointmentLoader"
              ><img
                class="img-fluid"
                src="../../assets/img/23. Close.svg"
                alt=""
              />
              Add</span
            >
          </button>
        </div>
      </form>
    </div>
    <!-- add-appointments -->

    <!-- reschedule-appointments -->
    <div class="add-appointments upd-appointments">
      <div class="modal-header">
        <h5 class="modal-title">Reschedule Appointment</h5>
        <a
          class="close hide-appi"
          data-dismiss="modal"
          aria-label="Close"
          v-on:click="toggleUpdateAppointment('hide')"
        >
          <span aria-hidden="true"
            ><img
              class="img-fluid"
              src="../../assets/img/23. Closex.svg"
              alt=""
          /></span>
        </a>
      </div>
      <form v-on:submit.prevent="updateAppointment">
        <div class="add-body">
          <div class="form-group">
            <label for="inputAddress">Date</label>
            <date-range-picker
              :min-date="yesterdayDate"
              :locale-data="{ format: 'mmmm dd, yyyy' }"
              v-model="updateApt.ufDate"
              :single-date-picker="true"
              :show-dropdowns="true"
              :auto-apply="true"
              :ranges="false"
            ></date-range-picker>
            <!-- <input type="date" class="form-control" placeholder="Pick your date" v-model="updateApt.date"> -->
            <div
              class="validate-error-message"
              v-if="$vd.updateApt.ufDate.$hasError === true"
            >
              {{ $vd.updateApt.$errors.ufDate[0] }}
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>Time</label>
              <vue-timepicker
                v-model="updateApt.time"
                format="h:mm A"
                name="timepicker"
                :minute-interval="5"
                placeholder="hh:mm a"
              ></vue-timepicker>
            </div>
            <div
              class="validate-error-message"
              v-if="$vd.updateApt.time.$hasError === true"
            >
              {{ $vd.updateApt.$errors.time[0] }}
            </div>
            <!-- <div class="form-group col-md-4">
                            <label for="inputCity">Time</label>
                            <select class="form-control" v-model="updateApt.tHour">
                                <option value="">hours</option>
                                <option v-for="(n, index) in 12" :key="index" v-bind:value="n">{{n}}</option>
                            </select>

                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputState"></label>
                            <select class="form-control" v-model="updateApt.tMin">
                                <option value="">minutes</option>
                                <option v-for="(n, index) in 60" :key="index" v-bind:value="n-1 | numberStep('2')">{{n-1 | numberStep('2')}}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputZip"></label>
                            <select class="form-control" v-model="updateApt.tDtype">
                                <option value="am">am</option>
                                <option value="pm">pm</option>
                            </select>
                        </div>
                        <div class="validate-error-message" v-if="$vd.updateApt.tHour.$hasError === true || $vd.updateApt.tMin.$hasError === true || $vd.updateApt.tDtype.$hasError === true">Pleae enter correct time</div> -->
          </div>
          <div
            class="form-group"
            v-if="$storage.get('auth').user.user_type == 1"
          >
            <label for="inputAddress2">Assign to</label>
            <select class="form-control" v-model="updateApt.assignTo">
              <option
                v-for="emp in employeesList"
                :key="emp.id"
                :value="emp.id"
              >
                {{ emp.name }}
              </option>
              <!-- <option>Brandon Wilson</option>
                            <option>Brandon Wilson</option> -->
            </select>
            <!-- <div class="validate-error-message" v-if="$vd.updateApt.assignTo.$hasError === true">{{$vd.updateApt.$errors.assignTo[0]}}</div> -->
          </div>
          <div class="form-group">
            <label for="inputAddress2"
              >Description
              <!--<span>(optional)</span>--></label
            >
            <textarea
              class="form-control"
              v-model="updateApt.desc"
              v-on:keyup="ResheduleliveCountDown"
              rows="5"
            ></textarea>
            <p>
              Total Remaining:
              <span v-bind:class="{ 'text-danger': generateErr }">{{
                totalRemainCount
              }}</span>
            </p>
            <div
              class="validate-error-message"
              v-if="$vd.updateApt.desc.$hasError === true"
            >
              {{ $vd.updateApt.$errors.desc[0] }}
            </div>
          </div>
        </div>
        <div class="modal-footer app-footer">
          <a
            href="#"
            class="add-btn"
            v-on:click="toggleUpdateAppointment('hide')"
          >
            Cancel</a
          >
          <button :disabled="appointmentLoader" type="submit" class="rem-btn">
            <span v-if="appointmentLoader"
              ><span class="spinner-grow spinner-grow-sm"></span>
              Loading...</span
            >
            <span v-if="!appointmentLoader"
              ><img
                class="img-fluid"
                src="../../assets/img/23. Close.svg"
                alt=""
              />
              Update</span
            >
          </button>
        </div>
      </form>
    </div>
    <!-- reschedule-appointments -->
  </div>
  <!-- appointments -->
</template>
<script>
import axios from "axios";
import VueDaval from "vue-daval";
import $ from "jquery";
import moment from "moment";
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "Appointments",
  props: ["defaultAssignTo", "employeesList"],
  components: {
    simplebar,
    DateRangePicker,
    VueTimepicker,
  },
  mixins: [VueDaval],
  data() {
    return {
      limitmaxCount: 40,
      totalRemainCount: 40,
      generateErr: false,
      active_apt: 0,
      yesterdayDate: moment().subtract(1, "day").format("YYYY-MM-DD"),
      lAppointments: [],
      addAppts: [],
      aptDate: { startDate: new Date() },
      addApt: {
        date: "",
        ufDate: { startDate: new Date(), endDate: new Date() },
        time: "",
        assignTo: this.$props.defaultAssignTo,
        desc: "",
        page: this.$route.name,
      },
      aptColors: ["green-meeting", "red-meeting", "blue-meeting"],
      updateApt: {
        id: null,
        date: "",
        ufDate: { startDate: new Date(), endDate: new Date() },
        time: "",
        assignTo: this.$props.defaultAssignTo,
        desc: "",
        page: this.$route.name,
      },
      appointmentLoader: false,
    };
  },
  vdRules: {
    addApt: {
      ufDate: { required: true },
      desc: { required: true },
      time: {
        required: true,
        checkAlias: (alias) => {
          if (
            alias != "" &&
            alias != undefined &&
            alias != "undefined" &&
            alias != null
          ) {
            if (alias.indexOf("hh") > -1) {
              return "This time is not valid. Please select complete time.";
            } else if (alias.indexOf("mm") > -1) {
              return "This time is not valid. Please select complete time.";
            } else if (alias.indexOf("AM") > -1) {
              return true;
            } else if (alias.indexOf("PM") > -1) {
              return true;
            } else {
              return "This time is not valid. Please select complete time.";
            }
          } else {
            return true;
          }
        },
      },
      //tMin:{required: true}, tDtype: {required: true}
    },
    updateApt: {
      id: { required: true },
      ufDate: { required: true },
      desc: { required: true },
      time: {
        checkAlias: (alias) => {
          if (
            alias != "" &&
            alias != undefined &&
            alias != "undefined" &&
            alias != null
          ) {
            if (alias.indexOf("hh") > -1) {
              return "This time is not valid. Please select complete time.";
            } else if (alias.indexOf("mm") > -1) {
              return "This time is not valid. Please select complete time.";
            } else if (alias.indexOf("AM") > -1) {
              return true;
            } else if (alias.indexOf("PM") > -1) {
              return true;
            } else {
              return "This time is not valid. Please select complete time.";
            }
          } else {
            return true;
          }
        },
      },
    },
  },
  created() {
    this.getAppointments();
    let vm = this;
    this.$root.$on("action", function (action, data) {
      if (action == "reschedule_apt") {
        vm.reScheduleApt(data);
      } else if (action == "update_appointment_status") {
        vm.updateAptStatus("all", 1, "future");
      } else if (action == "update_appointments") {
        vm.getAppointments();
      }
    });

    //console.log(this.$storage.get('auth').token)
    // if(this.$route.name == 'Dashboard'){
    //     this.getAppointments(0)
    // }else{
    //     this.getAppointments(this.$route.params.id)
    // }
  },
  mounted() {
    $('[data-toggle="tooltip"]').tooltip();
  },
  watch: {
    defaultAssignTo: function (rt) {
      this.addApt.assignTo = rt;
      this.getApptsForAddNew(this.$route.params.id);
      //this.updateApt.assignTo = rt
    },
  },
  methods: {
    liveCountDown: function () {
      this.totalRemainCount = this.limitmaxCount - this.addApt.desc.length;
      this.generateErr = this.totalRemainCount < 0;
    },
    ResheduleliveCountDown() {
      this.totalRemainCount = this.limitmaxCount - this.updateApt.desc.length;
      this.generateErr = this.totalRemainCount < 0;
    },
    deleteAppt(id) {
      axios
        .get("delete_appt/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            $(".tooltip").tooltip("hide");
            this.$root.$emit("action", "new_activity");
            this.getAppointments();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    reScheduleApt(apt) {
      console.log(apt);
      //if (apt.time_status != 'past') {
      //updateApt: {id:null,date:'',ufDate:{},tHour:'',tMin:'',tDtype:'am',assignTo:this.$props.defaultAssignTo,desc:''}
      this.updateApt.id = apt.ap_id;
      this.updateApt.desc = apt.ap_description;
      this.updateApt.assignTo = apt.ap_created_for;
      this.updateApt.ufDate.startDate = moment(apt.ap_date_time).format('MMMM DD, YYYY');
      this.updateApt.ufDate.endDate = moment(apt.ap_date_time).format('MMMM DD, YYYY');
      this.updateApt.time = moment(apt.ap_date_time).format('h:mm A');
      this.toggleUpdateAppointment("show");
      //console.log(this.updateApt)
      //}
    },
    formatAMPM(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
    updateAppointment() {
      this.appointmentLoader = true;
      if (this.$route.params.id) {
        this.updateApt.lead_id = this.$route.params.id;
      }
      if (this.totalRemainCount < 0) {
        this.$toast.open({
          position: "bottom",
          message: "Max limit is 40 characters",
          type: "info",
        });
        return false;
      }

      this.$vd.updateApt
        .$validate()
        .then(() => {
          let startDate = this.updateApt.ufDate.startDate;
          this.updateApt.date = moment(new Date(startDate)).format(
            "YYYY-MM-DD"
          );
          //console.log(this.updateApt);
          axios
            .post("update_apt", this.updateApt, {
              headers: {
                Authorization: "Bearer " + this.$storage.get("auth").token,
              },
            })
            .then((res) => {
              if (res.data.success) {
                this.appointmentLoader = false;
                this.toggleUpdateAppointment("hide");
                this.updateApt = {
                  id: null,
                  date: "",
                  ufDate: { startDate: new Date(), endDate: new Date() },
                  time: "",
                  assignTo: this.$props.defaultAssignTo,
                  desc: "",
                  page: this.$route.name,
                };
                this.getAppointments();
                this.$root.$emit("action", "new_activity");
                this.$root.$emit("action", "new_appointment");
              }
            })
            .catch((err) => {
              this.appointmentLoader = false;
              console.log(err);
            });
        })
        .catch(() => {
          this.appointmentLoader = false;
          console.log(this.$vd.addApt.$errors);
        });
    },
    updateAptStatus(id, status) {
      axios
        .post(
          "update_apt_status",
          { id: id, status: status, lead_id: this.$route.params.id },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            if (id == "all") {
              this.getAppointments();
            } else {
              if (status == 1) {
                $("#show" + id).addClass("okay-bg");
                $("#comp" + id).removeClass("okay-bg");
                $("#miss" + id).removeClass("notok-bg");

                $("#LTshow" + id).addClass("okay-bg");
                $("#LTcomp" + id).removeClass("okay-bg");
                $("#LTmiss" + id).removeClass("notok-bg");

                $("#mainAppt" + id)
                  .parent()
                  .removeClass("red-meeting green-meeting");
                $("#mainAppt" + id)
                  .parent()
                  .addClass("blue-meeting");
                $("#LTmainAppt" + id)
                  .parent()
                  .removeClass("red-meeting green-meeting");
                $("#LTmainAppt" + id)
                  .parent()
                  .addClass("blue-meeting");

                if (res.data.lead_status < 4) {
                  this.$root.$emit("action", "update_lead_status", 4);
                }
                this.getAppointments();
              } else if (status == 2) {
                $("#comp" + id).removeClass("okay-bg");
                $("#show" + id).removeClass("okay-bg");
                $("#miss" + id).addClass("notok-bg");
                $("#LTcomp" + id).removeClass("okay-bg");
                $("#LTshow" + id).removeClass("okay-bg");
                $("#LTmiss" + id).addClass("notok-bg");

                $("#mainAppt" + id)
                  .parent()
                  .removeClass("green-meeting blue-meeting");
                $("#mainAppt" + id)
                  .parent()
                  .addClass("red-meeting");
                $("#LTmainAppt" + id)
                  .parent()
                  .removeClass("green-meeting blue-meeting");
                $("#LTmainAppt" + id)
                  .parent()
                  .addClass("red-meeting");
              } else if (status == 3) {
                $("#show" + id).removeClass("okay-bg");
                $("#comp" + id).addClass("okay-bg");
                $("#miss" + id).removeClass("notok-bg");
                $("#LTshow" + id).removeClass("okay-bg");
                $("#LTcomp" + id).addClass("okay-bg");
                $("#LTmiss" + id).removeClass("notok-bg");

                $("#mainAppt" + id)
                  .parent()
                  .removeClass("red-meeting blue-meeting");
                $("#mainAppt" + id)
                  .parent()
                  .addClass("green-meeting");
                $("#LTmainAppt" + id)
                  .parent()
                  .removeClass("red-meeting blue-meeting");
                $("#LTmainAppt" + id)
                  .parent()
                  .addClass("green-meeting");
              }

              this.active_apt = this.active_apt - 1;
              this.$root.$emit("action", "new_activity");
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAppointments(by = "") {
      if (by == "date") {
        this.aptDate.date = moment(new Date(this.aptDate.startDate)).format(
          "YYYY-MM-DD"
        );
      } else {
        this.aptDate.date = "";
      }

      axios
        .post(
          "appointments/" + this.$route.params.id,
          { aptDate: this.aptDate.date },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            const data = res.data;
            this.lAppointments = data.success;
            this.active_apt = data.active_apt;

            setTimeout(() => {
              $('[data-toggle="tooltip"]').tooltip();
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getApptsForAddNew(id) {
      var startDate = moment(new Date(this.addApt.ufDate.startDate)).format(
        "YYYY-MM-DD"
      );
      axios
        .post(
          "appointments/" + id,
          { aptDate: startDate, aptUser: this.addApt.assignTo },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            const data = res.data;
            this.addAppts = data.success;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toggleAddAppointment(action) {
      if (action == "show") {
        //alert(this.active_apt)
        if (this.active_apt > 0) {
          alert("You already have an appointment with this customer.");
        } else {
          $(".add-appointment").addClass("show-appointments");
        }
      } else if (action == "hide") {
        $(".add-appointment").removeClass("show-appointments");
      }
    },
    toggleUpdateAppointment(action) {
      if (action == "show") {
        $(".upd-appointments").addClass("show-appointments");
      } else if (action == "hide") {
        $(".upd-appointments").removeClass("show-appointments");
      }
    },
    addNewAppointment() {
      this.appointmentLoader = true;
      if (this.$route.params.id) {
        this.addApt.lead_id = this.$route.params.id;
      }
      if (this.totalRemainCount < 0) {
        this.$toast.open({
          position: "bottom",
          message: "Max limit is 40 characters",
          type: "info",
        });
        this.appointmentLoader = false;
        return false;
      }

      this.$vd.addApt
        .$validate()
        .then(() => {
          let startDate = this.addApt.ufDate.startDate;
          this.addApt.date = moment(new Date(startDate)).format("YYYY-MM-DD");
          //console.log(this.addApt);
          axios
            .post("add_appointment", this.addApt, {
              headers: {
                Authorization: "Bearer " + this.$storage.get("auth").token,
              },
            })
            .then((res) => {
              if (res.data.success) {
                //contacted modal

                this.appointmentLoader = false;
                this.toggleAddAppointment("hide");
                this.addApt = {
                  date: "",
                  ufDate: { startDate: new Date(), endDate: new Date() },
                  time: "",
                  assignTo: this.$props.defaultAssignTo,
                  desc: "",
                  page: this.$route.name,
                };
                $(".vue__time-picker .clear-btn").trigger("click");
                $(".vue__time-picker .controls").trigger("click");
                this.getAppointments(this.$route.params.id);
                this.$root.$emit(
                  "action",
                  "update_touch_status",
                  res.data.lead_touch_resp_time
                );
                if (this.$route.name == "Dashboard") {
                  //this.getAppointments(0)
                } else {
                  //this.getAppointments(0)
                  //this.getAppointments(this.$route.params.id)
                  this.$root.$emit("action", "new_activity");
                }
                this.$root.$emit("action", "update_lead_status", 3);
                this.$root.$emit("action", "new_appointment");
                if (this.$store.state.contacted) {
                  console.log("appointment");
                 
                  this.$store.commit("updateContacted", false);
                  return false;
                }
                if (res.data.lead_status < 3) {
                  this.$root.$emit("action", "update_lead_status", 3);
                }
              }
            })
            .catch((err) => {
              this.appointmentLoader = false;
              console.log(err);
            });
        })
        .catch(() => {
          console.log(this.$vd.addApt.$errors);
          this.appointmentLoader = false;
        });
    },
  },
};
</script>

<style>
.add-appointment .vue-daterange-picker {
  width: 100% !important;
}
input.display-time {
  margin: 0;
  font-size: 14px !important;
  width: 100% !important;
  color: #6d778e;
  border: 1px solid #ccc !important;
  font-weight: 400;
  z-index: 2;
  background: transparent;
  cursor: pointer;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem !important;
  height: auto !important;
}
.vue__time-picker {
  width: 100% !important;
}
.vue__time-picker input.display-time:focus {
  border: 1px solid #ccc;
  outline: none;
  cursor: pointer;
}
.vue-daterange-picker {
  display: block !important;
}
.vue__time-picker input.display-time::-webkit-input-placeholder,
.vue__time-picker input.display-time::-ms-input-placeholder,
.vue__time-picker input.display-time::placeholder,
.vue__time-picker input.display-time:-ms-input-placeholder {
  color: #bbb;
}
</style>
