<template>
  <div>
    <div class="details-profile">
      <div class="accordion" id="accordionExamplePD">
        <div class="card">
          <div class="card-header" id="headingOnePD">
            <div class="mb-0 float-left">
              <button
                class="btn btn-link"
                type="button"
                data-toggle="collapse"
                data-target="#collapseOnePD"
              >
                <div class="detais-title">
                  <h3>
                    <img
                      class="img-fluid arrow-down"
                      src="../../assets/img/arrow-down.svg"
                      alt=""
                    />
                    Customer
                  </h3>
                </div>
              </button>
            </div>
            <div
              class="edit-profile blue-circle"
              data-toggle="modal"
              data-target="#editCustomerModal"
              v-on:click="updateCustomreArr"
            >
              <img
                class="img-fluid"
                src="../../assets/img/pencil.svg"
                alt="Edit Customer"
              />
            </div>
          </div>

          <div
            id="collapseOnePD"
            class="collapse show"
            aria-labelledby="headingOnePD"
            data-parent="#accordionExamplePD"
          >
            <div class="card-body">
              <div class="user-img">
                <div class="button-wrapper">
                  <a :href="'/customer/detail/' + lead_detail.l_customer_id">
                    <img
                      v-if="lead_detail.c_img"
                      class="img-fluid rounded50"
                      :src="lead_detail.c_img"
                      style="width: 100px; height: 100px"
                    />
                    <img
                      v-else
                      class="img-fluid"
                      src="../../assets/img/profile.svg"
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <h3>
                <img
                  v-if="lead_detail.c_do_contract == true"
                  style="width: 62px; position: relative; top: 18px"
                  data-toggle="tooltip" data-original-title="Do not contact"
                  src="../../assets/img/no_contact.png"
                />{{ lead_detail.c_first_name }} {{ lead_detail.c_last_name }}
              </h3>
              <table class="table">
                <tbody>
                  <tr v-if="lead_detail.c_company">
                    <td>Company Name:</td>
                    <td>{{ lead_detail.c_company }}</td>
                  </tr>
                  <tr>
                    <td>Email:</td>
                    <td>{{ lead_detail.c_email }}</td>
                  </tr>
                  <tr>
                    <td>Phone:</td>
                    <!-- <td>{{lead_detail.c_phone | removePlusSign()}}</td> -->
                    <td>{{ lead_detail.c_phone }}</td>
                  </tr>
                  <tr v-if="lead_detail.c_gender">
                    <td>Gender:</td>
                    <td>{{ lead_detail.c_gender }}</td>
                  </tr>
                  <tr
                    v-if="
                      lead_detail.c_street ||
                      lead_detail.c_city ||
                      lead_detail.c_state
                    "
                  >
                    <td>Address:</td>
                    <td>
                      {{ lead_detail.c_street }} {{ lead_detail.c_city }}
                      {{ lead_detail.c_state }}
                    </td>
                  </tr>
                  <tr v-if="lead_detail.ls_name">
                    <td>Source:</td>
                    <td v-if="lead_detail.ls_name == 'DealersGear'">
                      <a
                        class="cursor-pointer"
                        @click="openLeadSourceModal('leadsource')"
                        >{{ lead_detail.ls_name }}</a
                      >
                      <span
                        class="cursor-pointer"
                        @click="openLeadSourceModal('secondryleadsource')"
                        v-show="lead_detail.l_secondary_source"
                        >/ {{ lead_detail.l_secondary_source }}</span
                      >
                    </td>
                    <td v-else>
                      <span
                        class="cursor-pointer"
                        @click="openLeadSourceModal('leadsource')"
                        >{{ lead_detail.ls_name }}</span
                      >
                    </td>
                  </tr>
                  <tr v-if="lead_detail.lead_type">
                    <td>Type:</td>
                    <td>{{ lead_detail.lead_type }}</td>
                  </tr>
                  <tr v-if="lead_detail.l_id">
                    <td>ID:</td>
                    <td>#{{ lead_detail.l_id }}</td>
                  </tr>
                  <tr v-if="lead_detail.c_language_pref">
                    <td>Language Preference:</td>
                    <td>{{ lead_detail.c_language_pref }}</td>
                  </tr>
                  <tr v-if="lead_detail.c_comment">
                    <td colspan="2">{{ lead_detail.c_comment }}</td>
                  </tr>
                </tbody>
              </table>

              <div class="user-contacts">
                <ul>
                  <li>
                    <!-- makePhoneCall(lead_detail.c_phone) -->
                    <a
                      href="#"
                      v-on:click.stop.prevent="
                        makePhoneCall(lead_detail.c_phone)
                      "
                      ><img
                        class="img-fluid"
                        src="../../assets/img/Phone (1).svg"
                        alt=""
                      />
                      <p>Call</p></a
                    >
                  </li>
                  <li>
                    <a href="#" v-on:click.stop.prevent="activaTab('text')"
                      ><img
                        class="img-fluid"
                        src="../../assets/img/Phone (2).svg"
                        alt=""
                      />
                      <p>Text</p></a
                    >
                  </li>
                  <li>
                    <a href="#" v-on:click.stop.prevent="activaTab('email')"
                      ><img
                        class="img-fluid"
                        src="../../assets/img/Group 198.svg"
                        alt=""
                      />
                      <p>Email</p></a
                    >
                  </li>
                  <li v-if="lead_detail.facebook_messenger_id">
                    <a
                      href="#"
                      v-on:click.stop.prevent="activaTab('fb_message')"
                      ><img
                        class="img-fluid"
                        src="../../assets/img/mess.svg"
                        alt=""
                      />
                      <p>FB</p>
                    </a>
                  </li>
                  <!-- <li>
                    <router-link :to="{ path: '/message' }"
                      ><img
                        class="img-fluid"
                        src="../../assets/img/coun.svg"
                        alt=""
                      />
                      <p>Chat</p></router-link
                    >
                  </li> -->
                </ul>
              </div>
              <table class="table">
                <tbody>
                  <tr>
                    <td>Agent:</td>
                    <td>
                      <a
                        href="#"
                        data-toggle="modal"
                        :class="
                          lead_detail.assigned_user_str
                            ? 'd-link'
                            : 'assign-lead-btn'
                        "
                        data-target="#leadAssign"
                        v-on:click="leadAssignedTo"
                        ><span v-if="lead_detail.assigned_user_str">{{
                          lead_detail.assigned_user_str
                        }}</span>
                        <span v-else>Assign</span>
                      </a>
                      <!-- <a v-else data-toggle="modal" id="assin134" data-target="#leadAssign" class="assign-lead-btn" v-on:click="leadAssignedTo" href="#">Assign</a> -->
                    </td>
                  </tr>
                  <tr>
                    <td>BDC Agent:</td>

                    <td>
                      <a
                        href="#"
                        data-toggle="modal"
                        :class="
                          lead_detail.bdc_users_str
                            ? 'd-link'
                            : 'assign-lead-btn'
                        "
                        data-target="#bdcAssign"
                        v-on:click="bdcusers"
                        ><span v-if="lead_detail.bdc_users_str">{{
                          lead_detail.bdc_users_str
                        }}</span>
                        <span v-else>Assign</span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="user-contacts">
                <ul>
                  <li>
                    <button
                      v-if="
                        this.$storage.get('auth').user.user_type == 1 ||
                        this.$storage.get('auth').user.role_id == 4 ||
                        this.$storage.get('auth').user.role_id == 8 ||
                        this.$storage.get('auth').user.role_id == 5 ||
                        this.$storage.get('auth').user.role_id == 7 ||
                        this.$storage.get('auth').user.role_id == 10 ||
                        this.$storage
                          .get('auth')
                          .user.permissions.indexOf('7') != -1
                      "
                      style="
                        cursor: pointer;
                        background: rgb(255, 67, 67);
                        position: relative;
                        padding: 8px 25px;
                        line-height: 1;
                        font-size: 14px;
                        text-transform: none;
                      "
                      @click="deletelead"
                      class="rem-btn"
                    >
                      <!----><span style="font-weight: 700">Delete Lead </span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- profiles -->
    <div
      class="modal fade"
      id="editCustomerModal"
      role="dialog"
      aria-labelledby="editCustomerModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Edit Customer</h4>
            <a
              class="delas-colse close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>

          <form v-on:submit.prevent="editCustomer">
            <div class="modal-body">
              <div v-show="doCrop">
                <vue-cropper
                  :aspect-ratio="1 / 1"
                  :view-mode="1"
                  ref="cropper"
                  :src="cropImgUrl"
                  alt="Source Image"
                ></vue-cropper>
              </div>
              <!-- <div class="user-img">
                                <div class="button-wrapper">
                                    <span class="label">
                                    <img class="img-fluid" v-bind:src="imgUrl"  alt="" >
                                    </span><input type="file" ref="file" accept="image/*" v-on:change="handleFileUpload" class="upload upload-box" placeholder="Upload File">
                                </div>
                                <div class="edit-pro">
                                    <img class="img-fluid" src="../../assets/img/edit.svg" alt="">
                                </div>
                            </div> -->
              <div v-show="!doCrop">
                <div class="user-img">
                  <div class="button-wrapper">
                    <span class="label">
                      <template v-if="cropImgUrl">
                        <img class="img-fluid" :src="cropImgUrl" alt="" />
                      </template>
                      <template v-else-if="lead_detail.c_img">
                        <img
                          class="img-fluid"
                          :src="lead_detail.c_img"
                          style="width: 100px; height: 100px"
                          alt=""
                        />
                      </template>
                      <template v-else>
                        <img
                          v-if="!doCrop"
                          class="img-fluid"
                          :src="imgUrl"
                          alt=""
                        />
                      </template>
                    </span>
                    <input
                      type="file"
                      ref="file"
                      accept="image/*"
                      v-on:change="handleFileUpload"
                      class="upload upload-box"
                      placeholder="Upload File"
                    />
                  </div>
                  <!-- <div class="edit-pro">
                                        <img class="img-fluid" src="../../assets/img/edit.svg" alt="">
                                    </div> -->
                </div>
                <div class="add-body">
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <input
                        type="radio"
                        value="individual"
                        v-model="editCus.c_type"
                        name="utype"
                      />
                      Individual
                      <input
                        type="radio"
                        checked
                        value="company"
                        v-model="editCus.c_type"
                        name="utype"
                      />
                      Company
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label>First Name</label>
                      <input
                        v-model="editCus.c_first_name"
                        type="text"
                        class="form-control"
                      />
                      <div
                        class="validate-error-message"
                        v-if="$vd.editCus.c_first_name.$hasError === true"
                      >
                        {{ $vd.editCus.$errors.c_first_name[0] }}
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label>Last Name</label>
                      <input
                        v-model="editCus.c_last_name"
                        type="text"
                        class="form-control"
                      />
                      <div
                        class="validate-error-message"
                        v-if="$vd.editCus.c_last_name.$hasError === true"
                      >
                        {{ $vd.editCus.$errors.c_last_name[0] }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Email Address</label>
                    <input
                      v-model="editCus.c_email"
                      type="email"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label>Company Name</label>
                    <input
                      v-model="editCus.c_company"
                      type="text"
                      class="form-control"
                    />
                    <p style="color: red">{{ error }}</p>
                  </div>
                  <div class="form-group">
                    <label>Phone number</label>
                    <input
                      v-model="editCus.c_phone"
                      type="text"
                      class="form-control"
                      placeholder="Enter a phone number"
                    />
                    <!-- <vue-tel-input
                      v-model="editCus.c_phone"
                      v-bind="{
                        mode: 'international',
                        dynamicPlaceholder: true,
                        inputId: 'edc_phone',
                        enabledCountryCode: false,
                        maxLen: 15,
                        validCharactersOnly: true,
                        onlyCountries: ['us'],
                        defaultCountry: 'us',
                        placeholder: 'Enter a phone number',
                      }"
                    ></vue-tel-input> -->
                    <div
                      class="validate-error-message"
                      v-if="$vd.editCus.c_phone.$hasError === true"
                    >
                      {{ $vd.editCus.$errors.c_phone[0] }}
                    </div>
                  </div>
                  <!-- <div class="form-group">
                    <label>Address</label>
                    <input
                      v-model="editCus.c_address"
                      type="text"
                      class="form-control"
                      placeholder="Street address"
                    />
                  </div> -->
                  <div class="form-group">
                    <label>Street</label>
                    <input
                      v-model="editCus.c_street"
                      type="text"
                      class="form-control"
                      placeholder="Street address"
                    />
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label>Zip/Postal Code</label>
                      <input
                        v-model="editCus.c_zip"
                        type="text"
                        class="form-control"
                        placeholder="Zip/Postal Code"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label>State/Province</label>
                      <input
                        v-model="editCus.c_state"
                        type="text"
                        class="form-control"
                        placeholder="State/Province"
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label>City:</label>
                      <input
                        v-model="editCus.c_city"
                        type="text"
                        class="form-control"
                        placeholder="Enter city"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label>Apt/Unit/Suite </label>
                      <input
                        v-model="editCus.c_apt"
                        type="text"
                        class="form-control"
                        placeholder="Apt/Unit/Suite"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Sex</label>
                    <select class="form-control" v-model="editCus.c_gender">
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    <div
                      class="validate-error-message"
                      v-if="$vd.editCus.c_gender.$hasError === true"
                    >
                      {{ $vd.editCus.$errors.c_gender[0] }}
                    </div>
                  </div>
                  <div class="form-group">
                    <label style="display: block" for="inputAddress"
                      >Date of Birth</label
                    >
                    <!-- <date-range-picker
                      :locale-data="{ format: 'mmmm dd, yyyy' }"
                      v-model="ufDate"
                      :single-date-picker="true"
                      :show-dropdowns="true"
                      :auto-apply="true"
                      :ranges="false"
                    ></date-range-picker> -->
                    <!-- <date-picker
                      :date="startTime"
                      :option="option"
                      :limit="limit"
                    ></date-picker> -->
                    <date-picker v-model="dob" :config="options"></date-picker>
                  </div>
                  <div class="form-group">
                    <label>Communication Preference</label>
                    <select
                      class="form-control"
                      v-model="editCus.c_communication_pref"
                    >
                      <option value="">Select Preference</option>
                      <option value="email">Email</option>
                      <option value="call">Call</option>
                      <option value="sms">SMS</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label>Vehicle Preference</label>
                    <select
                      class="form-control"
                      v-model="editCus.c_vehicle_pref"
                    >
                      <option value="">Select Vehicle</option>
                      <option value="new">New Vehicle</option>
                      <option value="used">Used</option>
                      <option value="leased">Leased</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label>Language Preference</label>
                    <select
                      class="form-control"
                      v-model="editCus.c_language_pref"
                    >
                      <option value="">Select Language</option>
                      <option value="English">English</option>
                      <option value="French">French</option>
                      <option value="Spanish">Spanish</option>
                      <option value="Chinese">Chinese</option>
                      <option value="Arabic">Arabic</option>
                      <option value="Farsi">Farsi</option>
                      <option value="Tagalog">Tagalog</option>
                      <option value="Vietnamese">Vietnamese</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label>Customer Rating</label>
                    <select
                      class="form-control"
                      v-model="editCus.c_customer_rating"
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label>Driving License</label>
                    <input
                      v-model="editCus.c_driving_number"
                      type="text"
                      class="form-control"
                      placeholder="Enter driving license number"
                    />
                  </div>
                  <div class="form-group">
                    <label>SSN</label>
                    <input
                      v-model="editCus.c_ssn"
                      type="text"
                      class="form-control"
                      placeholder="Enter SSN"
                    />
                  </div>
                  <div class="form-group">
                    <div class="option-table setting-oppp">
                      <!-- check -->
                      <label class="containerw">
                        <input
                          type="checkbox"
                          @change="onChnagecontactHandeler($event)"
                          v-model="editCus.c_do_contract"
                        />
                        <span class="checkmark"></span>
                        Do not contact
                      </label>
                      <!-- check -->
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Comment</label>
                    <input
                      v-model="editCus.c_comment"
                      type="text"
                      class="form-control"
                      placeholder="Enter comment"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" v-if="!doCrop" class="rem-btn">
                <img
                  class="img-fluid"
                  src="../../assets/img/23. Close.svg"
                  alt=""
                />
                Update
              </button>
              <a
                href="#"
                v-if="doCrop"
                v-on:click="cancelCrop"
                data-dismiss="modal"
                class="add-btn"
                ><img
                  class="img-fluid"
                  src="../../assets/img/22. Delete.svg"
                  alt=""
                />
                Cancel</a
              >
              <a v-on:click="cropIt" class="rem-btn" v-if="doCrop">Crop</a>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- leads-assign area  -->
    <div class="modal fade" id="leadAssign" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Assign Lead</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <form v-on:submit.prevent="assignToUser">
            <div class="modal-body">
              <div class="add-member add-lead">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <multi-select
                      :searchable="true"
                      id="a_userss"
                      :alreadySelected="assign.to"
                      textColumn="text"
                      placeholder="Select users"
                      v-model="assign.to"
                      :options="allEmployees"
                      label="Users"
                    ></multi-select>

                    <!-- <multi-select :searchable="true" id="a_users" :alreadySelected="assign.to" textColumn="text" placeholder="Select users" v-model="assign.to" :options="allEmployees" label="Users"></multi-select> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <a href="#" class="add-btn" data-dismiss="modal"
                ><img
                  class="img-fluid"
                  src="../../assets/img/22. Delete.svg"
                  alt=""
                />
                Cancel</a
              >
              <button :disabled="btnLoader" type="submit" class="rem-btn">
                <span v-if="btnLoader"
                  ><span class="spinner-grow spinner-grow-sm"></span>
                  Loading...</span
                >
                <span v-if="!btnLoader"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/23. Close.svg"
                    alt=""
                  />
                  Assign</span
                >
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- / leads-assign area  -->
    <!-- leads-assign area  -->
    <div class="modal fade" id="leadsource" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Edit Lead Source</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <form v-on:submit.prevent="updateleadsource">
            <div class="modal-body">
              <div class="add-member add-lead">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <SingleSelect
                      :searchable="true"
                      id="aa_usersadss"
                      textColumn="text"
                      placeholder="Select Source"
                      v-model="source"
                      :options="LSources"
                      :alreadySelected="source"
                    ></SingleSelect>

                    <!-- <multi-select :searchable="true" id="a_users" :alreadySelected="assign.to" textColumn="text" placeholder="Select users" v-model="assign.to" :options="allEmployees" label="Users"></multi-select> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <a href="#" class="add-btn" data-dismiss="modal"
                ><img
                  class="img-fluid"
                  src="../../assets/img/22. Delete.svg"
                  alt=""
                />
                Cancel</a
              >
              <button
                :disabled="leadsourceloader"
                type="submit"
                class="rem-btn"
              >
                <span v-if="leadsourceloader"
                  ><span class="spinner-grow spinner-grow-sm"></span>
                  Loading...</span
                >
                <span v-if="!leadsourceloader"> Update</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- / leads-assign area  -->
    <!-- bdc assign -->
    <div class="modal fade" id="bdcAssign" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">BDC Rep</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <form v-on:submit.prevent="assignbdcusers">
            <div class="modal-body">
              <div class="add-member add-lead">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <multi-select
                      :searchable="true"
                      id="a_users"
                      :alreadySelected="bdcassign.to"
                      textColumn="text"
                      placeholder="Select users"
                      v-model="bdcassign.to"
                      :options="allbdcusers"
                      label="Users"
                    ></multi-select>

                    <!-- <multi-select :searchable="true" id="a_users" :alreadySelected="assign.to" textColumn="text" placeholder="Select users" v-model="assign.to" :options="allEmployees" label="Users"></multi-select> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <a href="#" class="add-btn" data-dismiss="modal"
                ><img
                  class="img-fluid"
                  src="../../assets/img/22. Delete.svg"
                  alt=""
                />
                Cancel</a
              >
              <button :disabled="btnLoader" type="submit" class="rem-btn">
                <span v-if="btnLoader"
                  ><span class="spinner-grow spinner-grow-sm"></span>
                  Loading...</span
                >
                <span v-if="!btnLoader"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/23. Close.svg"
                    alt=""
                  />
                  Assign</span
                >
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--- customer Already Exists Modal-->
    <div
      class="modal fade"
      id="custAlreadyExistModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="vehicleDetailModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
          <a class="delas-colse close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"
              ><img
                class="img-fluid"
                src="../../assets/img/23. Closex.svg"
                alt=""
            /></span>
          </a>
          <div class="modal-body Intersets-modal">
            <div class="row">
              <div class="col-12">
                <div class="details-modal">
                  <div class="container">
                    <p>Customer already in database</p>
                    <br />
                    <a
                      href="#"
                      v-on:click.stop.prevent="redirectIt(lead_detail)"
                      class="rem-btn redirect"
                      ><span> Go to Customer</span></a
                    >
                  </div>
                </div>
              </div>
              <!-- <div class="col-12 text-center mt-3">
                                <button class="rem-btn" style="display: initial">Select</button>
                            </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="notAssign" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"></h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>

          <div class="modal-body">
            <div class="add-member add-lead">
              <div class="form-row text-center">
                <div class="form-group col-md-12">
                  <p style="font-size: 22px; font-weight: 600">
                    This Lead is Not Assigned Yet
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--- customer Already Exists Modal-->
    <!-- customer alredy exist modal-->
    <div
      class="modal fade"
      id="customeralredyexist"
      role="dialog"
      aria-labelledby="customeralredyexist"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <a
              class="delas-colse close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>

          <div class="modal-body">
            <p>Customer with same email and phone already exists</p>
          </div>
          <div class="modal-footer">
            <a @click="redirectToCustomer(match_cus_id)" class="rem-btn">
              View Customer Detail
            </a>

            <button
              v-if="!doCrop"
              @click="merge(editCus.c_id)"
              :disabled="btnLoader"
              type="submit"
              class="rem-btn"
            >
              <span v-if="btnLoader"
                ><span class="spinner-grow spinner-grow-sm"></span>
                Loading...</span
              >
              <span v-if="!btnLoader"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Close.svg"
                  style="position: relative; top: 2px"
                  alt=""
                />
                Merge</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="creditModal"
      role="dialog"
      aria-labelledby="customeralredyexist"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <a
              class="delas-colse close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>

          <div class="modal-body">
            <div class="creadit-tabs">
              <form @submit.prevent="getScore">
                <div class="form-group row">
                  <label for="staticEmail" class="col-sm-2 col-form-label"
                    >Company</label
                  >
                  <div class="col-sm-10">
                    <div class="desk-radio">
                      <div class="form-group">
                        <label class="containerw">
                          CoreLogic
                          <input
                            type="radio"
                            value="CoreLogic"
                            v-model="company"
                            name="radio"
                          />
                          <span class="checkmark"></span>
                        </label>
                        <label class="containerw">
                          700 Credit
                          <input
                            type="radio"
                            value="700 credit"
                            v-model="company"
                            checked
                            name="radio"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="form-group row">
                  <label for="staticEmail" class="col-sm-2 col-form-label"
                    >Bureau</label
                  >
                  <div class="col-sm-10">
                    <div class="desk-radio">
                      <div class="form-group">
                        <label class="containerw">
                          Experian
                          <input
                            type="radio"
                            name="radio1"
                            value="Experian"
                            @change="changeHandeler('Experian')"
                            checked
                          />
                          <span class="checkmark"></span>
                        </label>
                        <label class="containerw">
                          Transunion
                          <input
                            type="radio"
                            value="Transunion"
                            @change="changeHandeler('Transunion')"
                            name="radio1"
                          />
                          <span class="checkmark"></span>
                        </label>
                        <label class="containerw">
                          Equifax
                          <input
                            type="radio"
                            value="Equifax"
                            @change="changeHandeler('Equifax')"
                            name="radio1"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <div class="form-group row">
                  <label for="staticEmail" class="col-sm-2 col-form-label"
                    >Client</label
                  >
                  <div class="col-sm-10">
                    <div class="desk-radio">
                      <div class="form-group">
                        <label class="containerw">
                          Buyer
                          <input
                            type="radio"
                            value="Buyer"
                            v-model="client"
                            name="radio2"
                            checked
                          />
                          <span class="checkmark"></span>
                        </label>
                        <label class="containerw">
                          Spouse
                          <input
                            type="radio"
                            value="Spouse"
                            v-model="client"
                            name="radio2"
                          />
                          <span class="checkmark"></span>
                        </label>
                        <label class="containerw">
                          Joint
                          <input
                            type="radio"
                            value="Joint"
                            v-model="client"
                            name="radio2"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="text-center">
                  <button
                    :disabled="btnLoader"
                    type="submit"
                    class="submit-btn"
                  >
                    <span v-if="btnLoader"
                      ><span class="spinner-grow spinner-grow-sm"></span>
                      Loading...</span
                    >
                    <span v-if="!btnLoader"> Submit</span>
                  </button>
                </div>
              </form>
              <!-- Dealership -->
              <div class="accordion" id="accordionExample33">
                <div class="card">
                  <div class="card-header" id="headingOne33">
                    <h2 class="mb-0">
                      <button
                        class="btn btn-link"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne33"
                        aria-expanded="true"
                        aria-controls="collapseOne31"
                      >
                        <div class="detais-title">
                          <h3>
                            <img
                              class="img-fluid arrow-down"
                              src="../../assets/img/arrow-down.svg"
                              alt=""
                            />Scores
                          </h3>
                        </div>
                      </button>
                    </h2>
                  </div>

                  <div
                    id="collapseOne33"
                    class="collapse show"
                    aria-labelledby="headingOne33"
                    data-parent="#accordionExample33"
                  >
                    <div class="card-body">
                      <div class="experian">
                        <div class="row">
                          <div class="col-sm-4">
                            <div class="experian-con border-right">
                              <p>Experian</p>
                              <h4>
                                {{ experian_score }}
                              </h4>
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="experian-con border-right">
                              <p>Transunion</p>
                              <h4>
                                {{ transunion_score }}
                              </h4>
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="experian-con">
                              <p>Equifax</p>
                              <h4>{{ equifax_score }}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Dealership -->

              <!-- Dealership -->
              <div class="accordion" id="accordionExample35">
                <div class="card">
                  <div class="card-header" id="headingOne35">
                    <h2 class="mb-0">
                      <button
                        class="btn btn-link"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne35"
                        aria-expanded="true"
                        aria-controls="collapseOne31"
                      >
                        <div class="detais-title">
                          <h3>
                            <img
                              class="img-fluid arrow-down"
                              src="assets/img/arrow-down.svg"
                              alt=""
                            />Reports History
                          </h3>
                        </div>
                      </button>
                    </h2>
                  </div>

                  <div
                    id="collapseOne35"
                    class="collapse show"
                    aria-labelledby="headingOne35"
                    data-parent="#accordionExample35"
                  >
                    <div class="card-body">
                      <!-- leads-table-area  -->
                      <div
                        class="leads-table-area report-table-area"
                        style="overflow-y: scroll; height: 400px"
                      >
                        <table class="display dataTable no-footer" id="report">
                          <thead>
                            <tr>
                              <th>Source</th>
                              <th>Bureau</th>
                              <th>Client</th>
                              <th>Date Pulled</th>
                              <th>Score</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="report in report"
                              :key="report.id"
                              :id="report.id"
                              :class="'row_id_' + report.id"
                            >
                              <td class="tpButton">
                                {{ report.credit_api_company }}
                              </td>
                              <td class="tpButton">{{ report.bureau }}</td>
                              <td class="tpButton">{{ report.client }}</td>
                              <td class="tpButton">
                                {{ report.created_at | formatDate }}
                              </td>
                              <td class="tpButton">{{ report.score }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- leads-table-area  -->

                      <div class="actions-btns">
                        <ul>
                          <li>
                            <a class="preview-btnn" @click="previewReport"
                              >Preview</a
                            >
                          </li>
                          <li><a @click="deleteReport">Delete</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Dealership -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="previewReport"
      tabindex="-1"
      role="dialog"
      aria-labelledby="Add Trade"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Preview Report</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <div v-html="html"></div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="deletelead"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-header"></div>
          <div class="modal-body">
            <center>
              <h5 style="color: blue">Are you sure?</h5>
              <p>You Want To Delete This Lead</p>
            </center>
          </div>
          <div class="modal-footer btn-center">
            <a href="#" class="add-btn" data-dismiss="modal"> Cancel</a>
            <button
              @click="deleteCustomerLead"
              :disabled="btnLoader"
              type="submit"
              class="rem-btn"
            >
              <span v-if="btnLoader"
                ><span class="spinner-grow spinner-grow-sm"></span>
                Loading...</span
              >
              <span v-if="!btnLoader"> Yes</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueDaval from "vue-daval";
import $ from "jquery";
//import simplebar from 'simplebar-vue';
//import 'simplebar/dist/simplebar.min.css';
import MultiSelect from "../helpers/MultiSelect";
import moment from "moment";
// import DateRangePicker from "vue2-daterange-picker";
// import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import VueCropper from "vue-cropperjs";
// import myDatepicker from "vue-datepicker";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import SingleSelect from "../helpers/singleSelect";
export default {
  name: "LeadProfile",
  props: ["lead_detail", "customer_id"],
  components: {
    MultiSelect,
    VueCropper,
    datePicker,
    SingleSelect,
  },
  mixins: [VueDaval],
  data() {
    return {
      dob: "",
      options: {
        format: "MM/DD/YYYY",
        useCurrent: false,
      },
      LSources: [],
      source: {},
      type: "",
      leadsourceloader: false,
      // for Vue 2.0
      error: "",
      startTime: {
        time: "",
      },
      endtime: {
        time: "",
      },
      option: {
        type: "day",
        week: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
        month: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        format: "MM-DD-YYYY",
        placeholder: "",
        inputStyle: {
          display: "block",
          width: "100%",
          padding: "0.375rem 0.75rem",
          "font-size": "14px",
          "font-weight": "400",
          "line-height": "1.5",
          color: "#343945",
          "background-color": "#fff",
          "background-clip": "padding-box",
          border: "1px solid #d5d8df",
          "border-radius": "0.25rem",
          transition:
            "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
        },
        color: {
          header: "#dbe5ff",
          headerText: "#3151a1",
        },
        buttons: {
          ok: "Ok",
          cancel: "Cancel",
        },
        overlayOpacity: 0.5, // 0.5 as default
        dismissible: true, // as true as default
      },

      limit: [],
      contact: false,
      report: [],
      html: "",
      experian_score: "",
      transunion_score: "",
      equifax_score: "",
      bureu: "Experian",
      client: "Buyer",
      company: "700 credit",
      btnLoader: false,
      match_cus_id: "",
      editCus: {},
      ufDate: { startDate: "", endDate: "" },
      allEmployees: [],
      allbdcusers: [],
      keyword: "",
      assign: {
        to: [],
        lead_id: 0,
        alreadyAssigned: this.alreadyAssignedUsers,
      },
      bdcassign: {
        to: [],
        lead_id: 0,
        alreadyAssigned: this.alreadyAssignedbdcusers,
        alreadyAssignedbdc: this.alreadyAssignedbdcusers,
      },
      alreadyAssignedUsers: [],
      alreadyAssignedbdcusers: [],
      imgUrl: require("../../assets/img/profile.svg"),
      cropImgUrl: null,
      doCrop: false,
    };
  },
  vdRules: {
    editCus: {
      c_first_name: { required: true },
      c_last_name: { required: true },
      c_phone: { required: true },
      c_gender: { required: true },
    },
  },

  created() {
    this.getAllEmployees();
    this.getAllBdcUsers();
    this.getLeadSource();
    $("#notAssign").modal("show");
    let v = this;
    this.$root.$on("creditModal", function () {
      axios
        .get("/get/credit/" + v.customer_id, {
          headers: {
            Authorization: "Bearer " + v.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            v.report = res.data.report;

            $("#creditModal").modal("show");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });

    this.$root.$on("action", function (action) {
      if (action == "customerAlreadyexits") {
        $("#custAlreadyExistModal").modal("show");
      }
    });
    this.$root.$on("gotodesking", function () {
      if (this.$store.state.vehicle) {
        window.location.href = "/desking/" + v.$route.params.id;
      } else {
        this.$toast.open({
          position: "bottom-right",
          message: "Add a vehicle before proceeding to desking",
          type: "info",
        });
      }
    });
  },
  watch: {
    lead_detail: function () {
      if (
        this.lead_detail.assigned_users == undefined ||
        this.lead_detail.assigned_users == "undefined" ||
        this.lead_detail.assigned_users == null ||
        this.lead_detail.assigned_users == ""
      ) {
        if (
          this.$store.state.modal &&
          this.$storage.get("auth").user.user_type == 2
        ) {
          $("#notAssign").modal("show");
        } else if (
          this.$store.state.modal &&
          this.$storage.get("auth").user.user_type == 1
        ) {
          $("#leadAssign").modal("show");
          this.leadAssignedTo();
        }

        // this.assignusers = this.lead_detail.assigned_arr.split(",");
        // if (this.$storage.get("auth").user.user_type == 2) {
        //     if (this.assignusers.includes("2") == false) {
        //         $("#notAssign").modal("show");
        //     }
        // }else if (this.$storage.get("auth").user.user_type == 1) {
        //     if (this.assignusers.includes("2") == false) {
        //         $("#leadAssign").modal("show");
        //         this.leadAssignedTo()
        //     }
        // }
      }
    },
  },
  mounted() {
    $("#report").on("click", ".tpButton", function () {
      var id = $(this).closest("tr").attr("id");
      $("#report tr").removeClass("even highlights");
      $(".row_id_" + id).addClass("even highlights");
      $(".actions-btns").addClass("highlights");

      this.reportid = id;
      localStorage.setItem("id", id);
      console.log(localStorage.getItem("id"));
    });
    if (this.$route.query.customer) {
      if (this.$route.query.customer == "true") {
        $("#custAlreadyExistModal").modal("show");
      }
    }
  },
  methods: {
    openLeadSourceModal(source) {
      if (
        this.$storage.get("auth").user.user_type == 1 &&
        this.lead_detail.l_form_lead == 1
      ) {
        this.LSources.forEach((s) => {
          console.log(s);
          if (s.text == this.lead_detail.ls_name) {
            this.source = { id: s.id, text: s.text };
          }
        });

        this.type = source;
        $("#leadsource").modal("show");
      }
    },
    updateleadsource() {
      this.leadsourceloader = true;
      axios
        .post(
          "update/lead/source",
          {
            source: this.source,
            lead_id: this.$route.params.id,
            type: this.type,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            this.leadsourceloader = false;
            this.$root.$emit("action", "new_activity");
            $("#leadsource").modal("hide");
            this.$toast.open({
              position: "top-right",
              message: res.data.message,
              type: "success",
            });
            if (res.data.type == "secondryleadsource") {
              this.lead_detail.l_secondary_source = this.source.text;
            } else {
              this.lead_detail.ls_name = this.source.text;
            }
          }
        })
        .catch((err) => {
          this.leadsourceloader = false;
          console.log(err);
          this.$toast.open({
            position: "top-right",
            message: "Something Wrong",
            type: "info",
          });
        });
    },
    getLeadSource() {
      axios
        .get("dealer/lead/sources", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            if (res.data.success.length > 0) {
              var dd = [];
              $.each(res.data.success, function (key, value) {
                dd.push({ id: value.ls_id, text: value.ls_name });
              });
              this.LSources = dd;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onChnagecontactHandeler(event) {
      console.log(event);
      if (event.target.checked) {
        this.editCus.c_do_contract = true;
      } else {
        this.editCus.c_do_contract = false;
      }
    },
    deletelead() {
      $("#deletelead").modal("show");
      // if(this.$storage.get("auth").user.user_type==1 || this.$storage.get('auth').user.permissions.indexOf('8') !=-1  ){

      //   $('#deletelead').modal('show')

      // }
      //  if(this.$storage.get("auth").user.user_type==2 && this.$storage.get('auth').user.permissions.indexOf('8') ==-1  ){
      //    //checking permissions
      //    if(this.$storage.get('auth').user.permissions.indexOf('7') !=-1){
      //      $('#deletelead').modal('show')
      //    }else{
      //       this.permission = "You don't have permession to delete this leadsheet.Please ask your manager for assistance"
      //       $('#permission').modal('show')
      //    }
      // }
    },
    deleteCustomerLead() {
      this.btnLoader = true;
      axios
        .get("delete/" + this.$route.params.id + "/" + this.lead_detail.c_id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.status) {
            $("#deletelead").modal("hide");
            $("#leadAssign").modal("hide");
            this.btnLoader = true;
            this.$router.push("/leads");
          } else {
            this.btnLoader = true;
            this.permission = res.data.message;
            $("#permission").modal("show");
            $("#deletelead").modal("hide");
          }
        })
        .catch((err) => {
          this.btnLoader = true;
          console.log(err);
        });
    },
    redirectToCustomer(id) {
      $("#customeralredyexist").modal("hide");
      setTimeout(() => {
        this.$router.push({ name: "customerDetail", params: { id: id } });
      }, 400);
    },
    getScore() {
      this.$toast.open({
        position: "top-right",
        message: "Request is in process",
        type: "info",
      });
      axios
        .post(
          "get/schore",
          {
            bureu: this.bureu,
            company: this.company,
            client: this.client,
            customer: this.customer_id,
            address: this.lead_detail.c_street,
            zip: this.lead_detail.c_zip,
            state: this.lead_detail.c_state,
            fname: this.lead_detail.c_first_name,
            lname: this.lead_detail.c_last_name,
            city: this.lead_detail.c_city,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            if (res.data.message) {
              this.btnLoader = false;
              this.$toast.open({
                position: "top-right",
                message: res.data.message,
                type: "info",
              });
            } else {
              this.$toast.open({
                position: "top-right",
                message: "Request Completed",
                type: "success",
              });
              this.experian_score = "";
              this.transunion_score = "";
              this.equifax_score = "";

              if (res.data.bureu == "Experian") {
                this.experian_score = res.data.score;
              }
              if (res.data.bureu == "Transunion") {
                this.transunion_score = 0;
              }
              if (res.data.bureu == "Equifax") {
                this.equifax_score = res.data.score;
              }
              if (res.data.score != "No score found") {
                this.previewReport();
              }
            }
          }
        })
        .catch((err) => {
          this.btnLoader = false;
          console.log(err);
          this.btnLoader = true;
        });
    },
    changeHandeler(val) {
      this.bureu = val;
    },
    deleteReport() {
      var id = localStorage.getItem("id");
      axios
        .get("report/delete/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.getCredit();
            this.$toast.open({
              position: "top-right",
              message: "Report Deleted",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCredit() {
      axios
        .get("/get/credit/" + this.customer_id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.report = res.data.report;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    previewReport() {
      var id = localStorage.getItem("id");
      console.log(id);
      console.log(id);
      axios
        .get("get/credit/preview/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.html = res.data.iframe;
            $("#previewReport").modal("show");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    bdcusers() {
      this.bdcassign.lead_id = this.$route.params.id;
      if (
        this.lead_detail.bdc_users !== undefined &&
        this.lead_detail.bdc_users != "undefined" &&
        this.lead_detail.bdc_users != null &&
        this.lead_detail.bdc_users != ""
      ) {
        var au = this.lead_detail.bdc_users.split(",");
        console.log(au);
        let vm = this;
        vm.alreadyAssignedbdcusers = [];
        vm.bdcassign.to.splice(0);
        $.each(au, function (i, v) {
          var aua = v.split("|");
          if (aua) {
            vm.alreadyAssignedbdcusers.push({ id: aua[0], text: aua[1] });
            vm.bdcassign.to.push({ id: aua[0], text: aua[1] });
          }

          //vm.assign.to.push({id:au_ids[i],text:v})
        });
        //console.log(vm.assign.to)
      }
    },
    merge(id) {
      this.btnLoader = true;
      axios
        .get("merge/" + id + "/" + this.match_cus_id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.status) {
            window.location.href = "";
          }
          this.btnLoader = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    redirectIt(customer) {
      console.log(customer.c_id);
      $("#custAlreadyExistModal").modal("hide");
      setTimeout(() => {
        this.$router.push("/customer/detail/" + customer.c_id);
      }, 400);
    },
    getAllEmployees() {
      axios
        .get("allnonbdcusers", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.success) {
            if (data.success.length > 0) {
              var dd = [];
              $.each(data.success, function (key, value) {
                dd.push({ id: value.id, text: value.name });
              });
              this.allEmployees = dd;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAllBdcUsers() {
      axios
        .get("allbdcusers", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.success) {
            if (data.success.length > 0) {
              var dd = [];
              $.each(data.success, function (key, value) {
                dd.push({ id: value.id, text: value.name });
              });
              this.allbdcusers = dd;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    leadAssignedTo() {
      this.assign.lead_id = this.$route.params.id;

      if (
        this.lead_detail.assigned_users !== undefined &&
        this.lead_detail.assigned_users != "undefined" &&
        this.lead_detail.assigned_users != null &&
        this.lead_detail.assigned_users != ""
      ) {
        var au = this.lead_detail.assigned_users.split(",");
        //var au_ids = this.lead_detail.assigned_user_ids.split(',');
        let vm = this;
        vm.alreadyAssignedUsers = [];
        vm.assign.to.splice(0);
        $.each(au, function (i, v) {
          var aua = v.split("|");
          if (aua) {
            vm.alreadyAssignedUsers.push({ id: aua[0], text: aua[1] });
            vm.assign.to.push({ id: aua[0], text: aua[1] });
          }

          //vm.assign.to.push({id:au_ids[i],text:v})
        });
        //console.log(vm.assign.to)
      }
    },
    assignbdcusers() {
      this.bdcassign.alreadyAssigned = this.alreadyAssignedbdcusers;
      this.btnLoader = true;
      console.log(this.assign);
      axios
        .post("lead_assign", this.bdcassign, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.alreadyAssignedbdcusers = res.data.new_assigned;
            this.btnLoader = false;
            this.$root.$emit("action", "new_activity");
            let vm = this;

            vm.lead_detail.bdcusers = "";
            vm.lead_detail.bdc_users_str = "";

            $.each(this.bdcassign.to, function (key, value) {
              vm.lead_detail.bdc_users += ",";
              vm.lead_detail.assigned_bdc_id += ",";

              vm.lead_detail.bdc_users += value.id + "|" + value.text;
              vm.lead_detail.bdc_users_str += value.text;
            });

            $("#bdcAssign").modal("hide");
            // if (res.data.noti_emails.length > 0) {
            //   $.each(res.data.noti_emails, function (key, value) {
            //     vm.$socket.emit("send_noti", { receiver: value });
            //   });
            // }
          }
        })
        .catch((err) => {
          this.btnLoader = false;
          console.log(err);
        });
    },
    assignToUser() {
      this.assign.alreadyAssigned = this.alreadyAssignedUsers;
      this.btnLoader = true;
      console.log(this.assign);
      axios
        .post("lead_assign", this.assign, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.btnLoader = false;
            this.alreadyAssignedUsers = res.data.new_assigned;

            this.$root.$emit("action", "new_activity");
            let vm = this;

            this.lead_detail.assigned_users = "";
            this.lead_detail.assigned_user_str = "";

            $.each(this.assign.to, function (key, value) {
              if (key != 0) {
                vm.lead_detail.assigned_users += ",";
                vm.lead_detail.assigned_user_ids += ",";
              }
              vm.lead_detail.assigned_users += value.id + "|" + value.text;
              vm.lead_detail.assigned_user_str += value.text;
            });

            $("#leadAssign").modal("hide");
            $("#bdcAssign").modal("hide");

            if (res.data.noti_emails.length > 0) {
              $.each(res.data.noti_emails, function (key, value) {
                vm.$socket.emit("send_noti", { receiver: value });
              });
            }
          }
        })
        .catch((err) => {
          this.btnLoader = false;
          console.log(err);
        });
    },
    makePhoneCall() {
      this.$root.$emit("action", "openCallModal");
      // this.$confirm({
      //   title: `Make a Call?`,
      //   message: "Phone: " + customer_phone,
      //   button: {
      //     no: "No",
      //     yes: "Yes",
      //   },
      //   callback: (confirm) => {
      //     if (confirm) {
      //       //console.log(customer_phone)
      //       axios
      //         .post(
      //           "call",
      //           { customerphone: customer_phone },
      //           {
      //             headers: {
      //               Authorization: "Bearer " + this.$storage.get("auth").token,
      //             },
      //           }
      //         )
      //         .then((res) => {
      //           console.log(res.data);
      //         })
      //         .catch((err) => {
      //           console.log(err);
      //         });
      //     }
      //   },
      // });
    },
    activaTab(tab) {
      $('.nav-tabs a[href="#' + tab + '"]').tab("show");
      $("html, body").animate(
        {
          scrollTop: $('.nav-tabs a[href="#' + tab + '"]').offset().top,
        },
        1000
      );
    },
    updateCustomreArr() {
      this.error = "";
      this.dob = moment(this.lead_detail.c_dob).format("MM/DD/YYYY")
      this.editCus = {
        c_first_name: this.lead_detail.c_first_name,
        c_last_name: this.lead_detail.c_last_name,
        c_email: this.lead_detail.c_email,
        c_phone: this.lead_detail.c_phone,
        c_comment: this.lead_detail.c_comment,
        c_ssn: this.lead_detail.c_ssn,
        c_driving_licence: this.lead_detail.c_driving_licence,
        c_customer_rating: this.lead_detail.c_customer_rating,
        c_vehicle_pref: this.lead_detail.c_vehicle_pref,
        c_communication_pref: this.lead_detail.c_communication_pref,
        c_street: this.lead_detail.c_street,
        c_zip: this.lead_detail.c_zip,
        c_state: this.lead_detail.c_state,
        c_city: this.lead_detail.c_city,
        c_apt: this.lead_detail.c_apt,
        c_gender: this.lead_detail.c_gender,
        c_id: this.lead_detail.c_id,
        //c_dob: moment(this.lead_detail.c_dob).format("MM/DD/YYYY"),
        c_language_pref: this.lead_detail.c_language_pref,
        c_img: this.lead_detail.picture,
        c_company: this.lead_detail.c_company,
        c_type: this.lead_detail.c_type,
        c_do_contract: this.lead_detail.c_do_contract == "1" ? true : false,
      };
      this.startTime = {
        time: this.lead_detail.c_dob,
      };
      this.ufDate.startDate = this.lead_detail.c_dob;
      this.ufDate.endDate = this.lead_detail.c_dob;
    },
    editCustomer() {
      //console.log(this.editCus)
      if (this.editCus.c_type == "company") {
        if (this.editCus.c_company == null || this.editCus.c_company == "") {
          this.error = "Company field is required";
          return false;
        }
      }
      let startDate = this.dob;
      this.editCus.c_dob = moment(startDate).format("YYYY-MM-DD");
      this.editCus.lead_id = this.$route.params.id;
      //console.log(moment(String(startDate)).format('YYYY-MM-DD'));
      axios
        .post("update_customer", this.editCus, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          //console.log(res.data)
          if (res.data.success) {
            if (res.data.permission == false) {
              this.lead_detail.c_is_first_sms = 1;
              this.lead_detail.c_sms_permission = 0;
            }
            this.$root.$emit("action", "new_activity");
            this.lead_detail.c_img = this.editCus.c_img;
            this.lead_detail.c_first_name = this.editCus.c_first_name;
            this.lead_detail.c_last_name = this.editCus.c_last_name;
            this.lead_detail.c_email = this.editCus.c_email;
            this.lead_detail.c_phone = this.editCus.c_phone;
            this.lead_detail.c_comment = this.editCus.c_comment;
            this.lead_detail.c_ssn = this.editCus.c_ssn;
            this.lead_detail.c_driving_licence = this.editCus.c_driving_licence;
            this.lead_detail.c_customer_rating = this.editCus.c_customer_rating;
            this.lead_detail.c_vehicle_pref = this.editCus.c_vehicle_pref;
            this.lead_detail.c_communication_pref =
              this.editCus.c_communication_pref;
            this.lead_detail.c_gender = this.editCus.c_gender;
            this.lead_detail.c_street = this.editCus.c_street;
            this.lead_detail.c_zip = this.editCus.c_zip;
            this.lead_detail.c_state = this.editCus.c_state;
            this.lead_detail.c_city = this.editCus.c_city;
            this.lead_detail.c_apt = this.editCus.c_apt;
            this.lead_detail.c_dob = moment(this.editCus.c_dob).format(
              "MM/DD/YYYY"
            );

            this.lead_detail.c_language_pref = this.editCus.c_language_pref;
            this.lead_detail.c_type = this.editCus.c_type;
            this.lead_detail.c_do_contract = this.editCus.c_do_contract;
            $("#editCustomerModal").modal("hide");
            this.$root.$emit("action", "update_customer_email");
          } else if (res.data.status == false) {
            $("#customeralredyexist").modal("show");
            $("#editCustomerModal").modal("hide");
            this.match_cus_id = res.data.id;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleFileUpload(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropImgUrl = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      this.doCrop = true;

      //this.imgUrl = URL.createObjectURL(this.$refs.file.files[0]);
    },
    cropIt() {
      this.cropImgUrl = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.editCus.c_img = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.doCrop = false;
    },
    cancelCrop() {
      this.doCrop = false;
    },
  },
};
</script>
<style scoped>
.redirect {
  position: relative;
  left: 30px;
}
.actions-btns ul li a {
  cursor: pointer;
}
.vue-daterange-picker {
  position: static;
  display: block;
  min-width: 60px;
}
.cov-vue-date {
  width: 100% !important;
}
.sourceby .customMS {
  width: 100%;
  margin-left: 15px;
}
.sourceby .customMS-drop h4 {
  border-radius: 30px;
  font-size: 12px;
  border: none;
  box-shadow: 0px 4px 8px rgba(49, 81, 161, 0.08);
}
.sourceby .show-options {
  top: 33px !important;
}
.sourceby .customMS-search input {
  margin: 0;
}
</style>