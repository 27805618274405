<template>
  <!-- Alert-area -->
  <div>
    <div class="Alert-area">
      <div class="accordion" id="accordionExample6">
        <div class="card">
          <div class="card-header" id="headingOne6">
            <div class="mb-0">
              <button
                class="btn btn-link"
                type="button"
                data-toggle="collapse"
                data-target="#collapseOne6"
                aria-expanded="true"
                aria-controls="collapseOne6"
              >
                <div class="detais-title">
                  <h3>
                    <img
                      class="img-fluid arrow-down"
                      src="../../assets/img/arrow-down.svg"
                      alt=""
                    />
                    Alerts
                  </h3>
                </div>
              </button>
            </div>
          </div>

          <div
            id="collapseOne6"
            :class="alerts.length > 0 ? 'collapse show' : 'collapse'"
            aria-labelledby="headingOne6"
            data-parent="#accordionExample5"
          >
            <div class="card-body">
              <simplebar class="all-notes">
                <div
                  v-for="alert in alerts"
                  :key="alert.la_id"
                  v-bind:class="{
                    'alert-warning' : alert.la_type == 1 && alert.la_status != 1,
                    'alert-danger' : alert.la_type != 1 && alert.la_status != 1,
                    'alert-info' : alert.la_status == 1,
                  }"
                  class="alert"
                  role="alert"
                >
                  <template v-if="alert.la_status != 1">
                    <img
                      v-if="alert.la_type == 2"
                      class="img-fluid"
                      src="../../assets/img/danger.svg"
                      alt=""
                    />
                    <img
                      v-if="alert.la_type == 1"
                      class="img-fluid"
                      src="../../assets/img/warning.svg"
                      alt=""
                    />
                  </template>
                  <template v-else>
                    <img
                      class="img-fluid"
                      src="../../assets/img/success.svg"
                      alt=""
                    />
                  </template>
                  {{ alert.la_text }}
                </div>

                <!-- <div class="alert alert-success" role="alert"><img class="img-fluid" src="../../assets/img/success.svg" alt="" /> Customer updated</div>
                            <div class="alert alert-danger" role="alert"><img class="img-fluid" src="../../assets/img/danger.svg" alt="" /> Customer hasn't been touched for 5 mins</div> -->
              </simplebar>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Alert-area -->
    <div class="row">
      <div class="col-md-6">
        <button @click="mergelead" class="rem-btn">
          <!----><span style="font-weight: 700">Merge</span>
        </button>
      </div>
    </div>

    <div
      class="modal fade"
      id="mergeLead"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Merge Leadsheet</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <form @submit.prevent="MergeCustomerLead">
            <div class="modal-body">
              <div class="add-member add-lead">
                <div class="form-group">
                  <select class="form-control" required v-model="mergeid">
                    <option value="">Select Lead</option>
                    <option
                      v-for="lead in leads"
                      :key="lead.id"
                      :value="lead.l_id"
                    >
                      {{ lead.l_id }} - {{ lead.status }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <a href="#" class="add-btn" data-dismiss="modal"> Cancel</a>
              <button :disabled="btnLoader" type="submit" class="rem-btn">
                <span v-if="btnLoader"
                  ><span class="spinner-grow spinner-grow-sm"></span>
                  Loading...</span
                >
                <span v-if="!btnLoader"> Merge</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- permission modal-->
    <div
      class="modal fade"
      id="permission"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"></h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <div class="modal-body">
            <p style="color: black; padding: 30px">{{ permission }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import $ from "jquery";
import Swal from "sweetalert2";

export default {
  name: "LeadAlerts",
  props: ["lead_detail"],
  data() {
    return {
      alerts: [],
      leads: [],
      permission: "",
      mergeid: "",
      btnLoader: false,
    };
  },
  components: {
    simplebar,
  },
  created() {
    //if(this.$storage.get('auth').user.user_type == 1){
    this.getAlerts(this.$route.params.id);

    //}
  },
  watch: {
    lead_detail: function (newVal) {
      //console.log(newVal)
      this.customerLeads(newVal.l_customer_id);
      // this.current_status = newVal
      // if (newVal == 6 || newVal == 7) {
      //     this.visit = 'desk'
      // }else{
      //     this.visit = newVal
      // }
    },
  },

  methods: {
    mergelead() {
      $("#mergeLead").modal("show");
      // if(this.$storage.get("auth").user.user_type==1 ||  this.$storage.get('auth').user.permissions.indexOf('8') !=-1 ){

      //   $('#mergeLead').modal('show')

      // }
      //  if(this.$storage.get("auth").user.user_type==2 &&  this.$storage.get('auth').user.permissions.indexOf('8') ==-1  ){
      //    //checking permissions
      //    if(this.$storage.get('auth').user.permissions.indexOf('6') !=-1){
      //      $('#mergeLead').modal('show')
      //    }else{
      //      this.permission = "You don't have permession to merge this leadsheet.Please ask your manager for assistance"
      //       $('#permission').modal('show')
      //    }
      // }
    },
    MergeCustomerLead() {
      Swal.fire({
        title: "Are you sure you want to merge?",
        showCancelButton: true,
        confirmButtonText: "Merge",
        denyButtonText: `Don't Merge`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.btnLoader = true;
          axios
            .get("merge/lead/" + this.$route.params.id + "/" + this.mergeid, {
              headers: {
                Authorization: "Bearer " + this.$storage.get("auth").token,
              },
            })
            .then((res) => {
              if (res.data.status) {
                $("#mergeLead").modal("hide");
                this.btnLoader = false;
                window.location.href = "";
              } else {
                this.permission = res.data.message;
                $("#permission").modal("show");
                $("#mergeLead").modal("hide");
                this.btnLoader = false;
              }
            })
            .catch((err) => {
              console.log(err);
              this.btnLoader = false;
            });
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    },

    getAlerts(id) {
      axios
        .get("alerts/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            const data = res.data;
            this.alerts = data.success;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //gettinf leads id of customer which are not sold ur lost
    customerLeads(id) {
      axios
        .post(
          "customer_leads",
          { lead_id: this.$route.params.id, customer_id: id, type: "active" },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            const data = res.data;
            //console.log(data.success)
            this.leads = data.success;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.btn-center {
  justify-content: center;
}
.leaddetails-right .row {
  display: grid;
  grid-template-columns: auto auto;
}
.leaddetails-right .row .col-md-6:nth-child(1) {
  padding-right: 0;
}
.leaddetails-right .row {
  display: grid;
  grid-template-columns: auto auto;
}
</style>