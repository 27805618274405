<template>
  <!-- Communication -->
  <div class="communication">
    <div class="accordion" id="accordionExample2">
      <div class="card">
        <div class="card-header" id="headingOne2">
          <div class="mb-0">
            <button
              class="btn btn-link"
              type="button"
              data-toggle="collapse"
              data-target="#collapseOne2"
            >
              <div class="detais-title">
                <h3>
                  <img
                    class="img-fluid arrow-down"
                    src="../../assets/img/arrow-down.svg"
                    alt=""
                  />
                  Communication
                </h3>
              </div>
            </button>
          </div>
        </div>

        <div
          id="collapseOne2"
          class="collapse show"
          aria-labelledby="headingOne2"
          data-parent="#accordionExample2"
        >
          <div class="card-body">
            <div class="communin-links">
              <div class="row">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li
                    @click="tabOpen('email')"
                    :class="
                      lead_detail.facebook_messenger_id
                        ? 'nav-item col-sm-4 pr-2'
                        : 'nav-item col-sm-6 pr-2'
                    "
                  >
                    <a
                      class="nav-link sms"
                      id="email-tab"
                      @click="tabOpen('email')"
                      role="tab"
                      data-toggle="tab"
                      href="#emailcommunicationtab"
                      aria-controls="email"
                      aria-selected="true"
                      :class="isMailContainerActive ? 'active' : ''"
                      ><img
                        class="img-fluid"
                        src="../../assets/img/Vector (6).svg"
                        alt=""
                      />
                      Send Email</a
                    >
                  </li>
                  <li
                    @click="()=>{getSMS(true)}"
                    :class="
                      lead_detail.facebook_messenger_id
                        ? 'nav-item col-sm-4 pr-2'
                        : 'nav-item col-sm-6 pr-2'
                    "
                  >
                    <a
                      class="nav-link sms"
                      id="text-tab"
                      @change="scrollToEndSMSContainer()"
                      data-toggle="tab"
                      href="#text"
                      role="tab"
                      :class="isSmsContainerActive ? 'active' : ''"
                      ><img
                        class="img-fluid"
                        src="../../assets/img/Vector (7).svg"
                        alt=""
                      />
                      Send Text
                      <span v-if="MessagesUnread > 0" class="tab-badge-right">{{ MessagesUnread }}</span>
                      </a
                    >
                  </li>
                  <li
                    @click="()=>{getFBMessages(true)}"
                    :class="
                      lead_detail.facebook_messenger_id
                        ? 'nav-item col-sm-4 pl-2'
                        : ''
                    "
                    v-if="lead_detail.facebook_messenger_id"
                  >
                    <a
                      class="nav-link"
                      @click="scrollToEndFbContainer"
                      id="fb_message-tab"
                      style="position: relative;"
                      data-toggle="tab"
                      href="#fb_message"
                      role="tab"
                      :class="isFbMessageContainerActive ? 'active' : ''"
                      ><img
                        class="img-fluid"
                        src="../../assets/img/mess-02.svg"
                        alt=""
                      />
                      Send FB Message
                      <span v-if="FbMessagesUnread > 0" class="tab-badge-right">{{ FbMessagesUnread }}</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade"
                  id="fb_message"
                  role="tabpanel"
                  aria-labelledby="email-tab"
                  :class="isFbMessageContainerActive ? 'show active' : ''"
                >
                  <!-- send-fbmessage -->
                  <div class="send-message">
                    <div class="message-content" id="CommunicationFBSection">
                      <div class="message-contentss">
                        <ul>
                          <li
                            v-for="msg in FbMessages"
                            :key="msg.id"
                            :class="
                              msg.msg_action == 'send'
                                ? 'replay text-right'
                                : 'sender'
                            "
                          >
                            <span class="send-time" style="display: block">
                              {{ msg.msg_date }} {{ msg.msg_time }}</span
                            >
                            <p v-if="msg.message">{{ msg.message }}</p>
                            <VuePureLightbox
                              v-if="msg.image"
                              :thumbnail="msg.image"
                              :images="[msg.image]"
                            />
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="message-box">
                      <form v-on:submit.prevent="sendfbMessage">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="input-group-text">
                              <!-- <img
                                class="img-fluid"
                                src="../../assets/img/emoji.svg"
                                alt=""
                              /> -->
                            </div>
                          </div>
                          <textarea
                            class="form-control"
                            placeholder="Type here..."
                            v-model="msg_.fbmessage"
                          ></textarea>
                        </div>
                        <button
                          v-if="!btnLoader"
                          type="submit"
                          :disabled="fbdisablebtn"
                          class="message-submit"
                        >
                          <img
                            class="img-fluid"
                            src="../../assets/img/sendmsg.svg"
                            alt=""
                          />
                        </button>
                        <span v-if="btnLoader"
                          ><span class="spinner-grow spinner-grow-sm"></span>
                          Loading...</span
                        >
                        <div class="image-upload">
                          <label for="fbfile">
                            <img
                              class="message-submit"
                              style="
                                width: 25px;
                                margin-top: -2px;
                                cursor: pointer;
                              "
                              src="../../assets/img/aad.svg"
                              alt=""
                            />
                          </label>

                          <input
                            v-on:change="onFileChange($event, 'facebook')"
                            class="file-input"
                            id="fbfile"
                            type="file"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                  <!-- send-fbmessage -->
                </div>

                <div
                  class="tab-pane fade"
                  id="emailcommunicationtab"
                  role="tabpanel"
                  aria-labelledby="email-tab"
                  :class="isMailContainerActive ? 'show active' : ''"
                >
                  <!-- send-email -->
                  <div class="send-email">
                    <form v-on:submit.prevent="sendEmail">
                      <div class="form-group light_input_box">
                        <span class="placeholder">To: </span>
                        <label for="exist-values">
                          <vue-tags-input
                            v-model="tag"
                            class="tags-input"
                            placeholder=""
                            :tags="email_.tags"
                            @tags-changed="(newTags) => (email_.tags = newTags)"
                            @before-adding-tag="checkTag"
                          />
                          <!-- <input type="text" id="exist-values" class="tagged form-control" data-removeBtn="true" name="tag-2" value="Mikle Cline (mikle.cline@xyz.com)" placeholder="To:"> -->
                        </label>
                      </div>
                      <div class="form-group add-member add-lead light_input_box">
                        <input
                          type="text"
                          placeholder="Subject"
                          style="background-color: #f6f7fa;"
                          class="form-control light_input_self"
                          v-model="email_.subject"
                        />
                        <!-- <input type="text" id="exist-values" class="tagged form-control" data-removeBtn="true" name="tag-2" value="Mikle Cline (mikle.cline@xyz.com)" placeholder="To:"> -->
                      </div>
                      <!-- <ckeditor
                        v-model="email_.body"
                        :config="editorConfig"
                      ></ckeditor> -->
                      <!-- <editor
                        v-model="email_.body"
                        :api-key="tinykey"
                        :init="editorConfig"
                      ></editor> -->
                      <textarea :id="`tiny_${tiny_indentifier}`"></textarea>

                      <div class="send-btns">
                        <div class="row">
                          <div class="col-md-7">
                            <div class="attach">
                              <select
                                class="form-control"
                                v-on:change="loadTemplate()"
                                v-model="emial_temp_"
                              >
                                <option value="">Select template</option>
                                <option
                                  v-for="temp in email_templates"
                                  :key="temp.ut_id"
                                  :value="temp"
                                >
                                  {{ temp.ut_title }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-5">
                            <div class="email-sends">
                              <a href="#" class="add-btn"> Cancel</a>
                              <button
                                type="submit"
                                class="rem-btn afteremil-btn"
                                :disabled="btnLoader"
                              >
                                <span
                                  v-if="btnLoader"
                                  class="spinner-grow spinner-grow-sm"
                                ></span>
                                <span v-if="!btnLoader">Send</span>
                              </button>
                              <!-- <a href="#" class="rem-btn afteremil-btn"> Send</a> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <!-- send-email -->

                  <!-- after-email -->
                  <div
                    class="reply-box"
                    v-for="mail in allEmails"
                    :key="mail.le_id"
                    :id="'mail-id-' + mail.le_id"
                  >
                    <div class="reply-header">
                      <div class="replay-img">
                        <img
                          v-if="mail.le_action == 'send'"
                          class="img-fluid"
                          :src="$storage.get('auth').user.picture"
                          alt=""
                        />
                        <img
                          v-if="
                            mail.le_action == 'receive' && lead_detail.c_img
                          "
                          class="img-fluid"
                          :src="lead_detail.c_img"
                          alt=""
                        />
                        <img
                          v-if="
                            mail.le_action == 'receive' && !lead_detail.c_img
                          "
                          class="img-fluid"
                          :src="c_img"
                          alt=""
                        />
                        <p v-if="mail.le_action == 'send'">Reply to:</p>
                        <p v-if="mail.le_action == 'receive'">Reply From:</p>
                      </div>
                      <div class="reply-email">
                        <p v-if="mail.first_name">
                          {{ mail.first_name }} {{ mail.last_name }}
                          <span>{{ mail.le_email }}</span>

                          <button class="close-btn">x</button>
                        </p>
                        <p v-else>
                          {{ mail.c_first_name }} {{ mail.c_last_name }}
                          <span>{{ mail.le_email }}</span>

                          <button class="close-btn">x</button>
                        </p>
                      </div>
                      <div class="expant">
                        <span
                          class="expend"
                          data-toggle="modal"
                          data-target="#exampleModal6"
                          ><img
                            class="img-fluid"
                            src="assets/img/22. Expand.svg"
                            alt=""
                        /></span>
                      </div>
                    </div>
                    <div
                      class="reply-boy"
                      :class="mail.le_action == 'send' ? 'greenbody' : ''"
                    >
                      <p v-html="mail.le_body"></p>
                      <div
                        style="
                          position: relative;
                          left: 33px;
                          bottom: 29px;
                          margin-top: 5px;
                        "
                      >
                        <span style="font-size: 12px; padding-right: 4px">{{
                          mail.f_date
                        }}</span>
                        <span style="font-size: 12px">{{ mail.f_time }}</span>
                      </div>
                    </div>
                  </div>

                  <!-- after-email -->
                </div>
                <!-- SMS Container -->
                <div
                  class="tab-pane fade"
                  id="text"
                  role="tabpanel"
                  aria-labelledby="text-tab"
                  :class="isSmsContainerActive ? 'show active' : ''"
                >
                  <div class="send-message">
                    <div
                      class="message-content"
                      id="CommunicationSmsSection"
                      style="max-height: 350px; height: 350px; overflow-y: auto"
                    >
                      <div class="message-contentss">
                        <ul>
                          <li
                            v-for="msg in allMessages"
                            :key="msg.msg_id"
                            :id="'msg-id-' + msg.msg_id"
                            :class="
                              msg.msg_action == 'send' ||
                              msg.msg_action == 'pending'
                                ? 'replay text-right'
                                : 'sender'
                            "
                          >
                            <span class="send-time" style="display: block">
                              {{ msg.msg_date }} {{ msg.msg_time }}</span
                            >
                            <p v-if="msg.msg_body" v-html="msg.msg_body"></p>
                            <VuePureLightbox
                              v-if="msg.image"
                              :thumbnail="msg.image"
                              :images="[msg.image]"
                            />
                          </li>
                          <!-- <li class="replay"><p>Great! What kinds are you planing Could you share with us </p><span class="send-time"> 7:35 AM</span></li>
                                                <li class="sender"><span class="send-time">7:35 AM</span> <p>Hi there!</p></li> -->
                        </ul>
                      </div>
                    </div>

                    <div class="message-box">
                      <form v-on:submit.prevent="sendSMS">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="input-group-text">
                              <!-- <img
                                class="img-fluid"
                                src="../../assets/img/emoji.svg"
                                alt=""
                              /> -->
                            </div>
                          </div>

                          <textarea
                            :disabled="
                              (lead_detail.c_is_first_sms == 0 &&
                                lead_detail.c_sms_permission == 0) ||
                              disableBtn
                            "
                            v-model="msg_.body"
                            class="form-control wrap-text"
                            placeholder="Type here..."
                          >
                          </textarea>
                        </div>
                        <button
                          v-if="!btnLoader"
                          type="submit"
                          class="message-submit"
                          :disabled="
                            (lead_detail.c_is_first_sms == 0 &&
                              lead_detail.c_sms_permission == 0) ||
                            disableBtn
                          "
                        >
                          <img
                            class="img-fluid"
                            src="../../assets/img/sendmsg.svg"
                            alt=""
                          />
                        </button>
                        <span v-if="btnLoader"
                          ><span class="spinner-grow spinner-grow-sm"></span>
                          Loading...</span
                        >

                        <div class="image-upload">
                          <label for="smsfile">
                            <img
                              style="
                                width: 25px;

                                cursor: pointer;
                              "
                              class="message-submit"
                              src="../../assets/img/aad.svg"
                              alt=""
                            />
                          </label>

                          <input
                            v-on:change="onFileChange($event, 'sms')"
                            class="file-input"
                            id="smsfile"
                            type="file"
                          />
                        </div>
                      </form>

                      <div class="send-btns">
                        <div class="row">
                          <div class="col-md-7">
                            <div class="attach">
                              <select
                                class="form-control"
                                v-on:change="loadtextTemplate()"
                                v-model="smstemp"
                              >
                                <option value="">Select template</option>
                                <option
                                  v-for="temp in sms_templates"
                                  :key="temp.id"
                                  :value="temp"
                                >
                                  {{ temp.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Communication -->
</template>
<script>
import axios from "axios";
import VueTagsInput from "@johmun/vue-tags-input";

//import CKEditor from "ckeditor4-vue"; ckeditor: CKEditor.component
import $ from "jquery";
import { tinymcekey } from "../../config.js";
import VuePureLightbox from "vue-pure-lightbox";
import "vue-pure-lightbox/dist/VuePureLightbox.css";
//import firebase from "firebase";
//import "firebase/firebase-messaging";
export default {
  name: "Communication",
  props: ["lead_detail"],
  components: { VueTagsInput, 
    // editor: Editor,
     VuePureLightbox },
  data() {
    return {
      tiny_indentifier:Math.floor(Math.random() * 10000),
      smstemp: "",
      sms_templates: [],
      c_img: require("../../assets/img/profile.svg"),
      file: "",
      fbdisablebtn: false,
      disableBtn: false,
      tinykey: tinymcekey,
      btnLoader: false,
      files: "",
      FbMessages: [],
      FbMessagesUnread : 0,
      MessagesUnread : 0,
      msg_: {
        body: "",
        fbmessage: "",
        customer_id: this.lead_detail.l_customer_id,
        lead_id: this.$route.params.id,
        phone: this.lead_detail.c_phone,
        c_name:
          this.lead_detail.c_first_name + " " + this.lead_detail.c_last_name,
        smsPermission: this.lead_detail.c_sms_permission,
        isFirstSms: this.lead_detail.c_is_first_sms,
        dealer_id: this.lead_detail.l_dealer_id,
        vehicle: this.lead_detail.l_vehicle_id,
      },
      allMessages: [],
      allEmails: [],
      tag: "",
      email_: {
        tags: [],
        body: "",
        lead_id: this.$route.params.id,
        files: "",
        c_id: this.lead_detail.c_id,
        c_email: this.lead_detail.c_email,
        c_name:
          this.lead_detail.c_first_name + " " + this.lead_detail.c_last_name,
      },
      editorConfig: {
        element_format: "html",
        relative_urls: false,
        remove_script_host: false,
        menubar: false,
        height: 300,
        branding: false,
        plugins: [
          "advlist autolink lists link image media charmap print preview anchor",
          "searchreplace visualblocks code",
        ],
        toolbar:
          "bold italic | alignleft aligncenter alignright | bullist numlist | link | image | media | code | formatselect",
        images_upload_handler: function (blobInfo, success, failure, progress) {
          var xhr, formData;

          xhr = new XMLHttpRequest();
          xhr.withCredentials = false;
          //console.log(axios.defaults.baseURL+'tinyImageUpload')
          xhr.open("POST", axios.defaults.baseURL + "tinyImageUpload");

          xhr.upload.onprogress = function (e) {
            progress((e.loaded / e.total) * 100);
          };

          xhr.onload = function () {
            var json;

            if (xhr.status === 403) {
              failure("HTTP Error: " + xhr.status, { remove: true });
              return;
            }

            if (xhr.status < 200 || xhr.status >= 300) {
              failure("HTTP Error: " + xhr.status);
              return;
            }

            json = JSON.parse(xhr.responseText);

            if (!json || typeof json.location != "string") {
              failure("Invalid JSON: " + xhr.responseText);
              return;
            }

            success(json.location);
          };

          xhr.onerror = function () {
            failure(
              "Image upload failed due to a XHR Transport error. Code: " +
                xhr.status
            );
          };

          formData = new FormData();
          formData.append("file", blobInfo.blob(), blobInfo.filename());

          xhr.send(formData);
        },
      },
      email_templates: [],
      emial_temp_: "",
      isMailContainerActive: true,
      isSmsContainerActive: false,
      isFbMessageContainerActive: false,
      notification_id: null,
      notification_type: null,
      tiny_editor : null,
    };
  },
  watch: {
    lead_detail: function (newVal) {
      this.getSMS(newVal.l_customer_id);
      this.getFBMessages(newVal.l_customer_id);
      this.getEmails();
      console.log(newVal);
    },
  },
  created() {
    //this.getSMS()
    //this.getEmails();
    this.getSmsTemplates();
    if (this.lead_detail.facebook_messenger_id) {
      this.getFBMessages();
    }

    this.getEmailTemplates();
    let vm = this;
    this.$root.$on("lead_detail_status", function (action) {
      if (action == "updated") {
        setTimeout(() => {
          if (vm.lead_detail.c_email) {
            vm.email_.tags.push({ text: vm.lead_detail.c_email });
          }
        }, 500);
      }
    });
    this.$root.$on("action", function (action, data) {
      if (action == "getEmail") {
        vm.getEmails();
      }
      if (action == "update_voi") {
        vm.lead_detail.l_vehicle_id = data;
      } else if (action == "update_lead_status") {
        vm.lead_detail.l_status = data;
      } else if (action == "update_customer_email") {
        vm.email_.tags["0"].text = vm.lead_detail.c_email;
      } else if (action == "fbnotification") {
        if (data.message) {
          if (
            data.type == "sms" &&
            data.receiver == vm.$storage.get("auth").user.email
          ) {
            console.log("repeat");
            // vm.allMessages.push({ msg_body: data.message });
            //vm.getSMS();
            // setTimeout(() => {
            //   vm.scrollToEndSMSContainer();
            // });
          }
          if (
            data.type == "facebook" &&
            data.receiver == vm.$storage.get("auth").user.email
          ) {
            vm.FbMessages.push({ message: data.message });
            vm.$store.commit(
              "updateResponseCount",
              vm.$store.state.engagements + 1
            );
            setTimeout(function () {
              vm.scrollToEndFbContainer();
              vm.FbMessagesUnread++;
              $("#fb_message").addClass("tab-pane");
            }, 1000);
          }
        }
        // vm.getFBMessages();
        // vm.getSMS();
      }
    });
  },
  mounted() {
    if(window.tinymce){
      setTimeout(()=>{
        window.tinymce.init({
          selector: `textarea#tiny_${this.tiny_indentifier}`,
          height: 300,
          menubar: false,
          plugins: [
              'advlist', 'autolink',
              'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
              'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | a11ycheck casechange blocks | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist checklist outdent indent autolink | removeformat image media  link lists | insertdatetime code table fullscreen',
          images_upload_handler: function (blobInfo, success, failure, progress) {
            var xhr, formData;

            xhr = new XMLHttpRequest();
            xhr.withCredentials = false;
            //console.log(axios.defaults.baseURL+'tinyImageUpload')
            xhr.open("POST", axios.defaults.baseURL + "tinyImageUpload");

            xhr.upload.onprogress = function (e) {
              progress((e.loaded / e.total) * 100);
            };

            xhr.onload = function () {
              var json;

              if (xhr.status === 403) {
                failure("HTTP Error: " + xhr.status, { remove: true });
                return;
              }

              if (xhr.status < 200 || xhr.status >= 300) {
                failure("HTTP Error: " + xhr.status);
                return;
              }

              json = JSON.parse(xhr.responseText);

              if (!json || typeof json.location != "string") {
                failure("Invalid JSON: " + xhr.responseText);
                return;
              }

              success(json.location);
            };

            xhr.onerror = function () {
              failure(
                "Image upload failed due to a XHR Transport error. Code: " +
                  xhr.status
              );
            };

            formData = new FormData();
            formData.append("file", blobInfo.blob(), blobInfo.filename());

            xhr.send(formData);
          },
          setup: (editor) => {
            editor.on('keyup', () => {
              // Get the current content as html
              const content = editor.getContent({ format: 'html' });
              this.email_.body = content;
              // Access other scope variables
              console.log('Content changed:', content);
              console.log('Other variable:', this.email_.body);
            });
          }
        }).then(()=>{
          this.tiny_editor = window.tinymce.get(`tiny_${this.tiny_indentifier}`);
        });
      },500)
    }
    window.Echo.channel("SmsChannel") //Should be Channel Name
      .listen("NewMessageEvent", (e) => {
        if (e.type == "permission_change") {
          this.lead_detail.c_sms_permission = 1;
          // this.getSMS();
        } else if (e.type == "increase_engagement") {
          this.$store.commit(
            "updateResponseCount",
            this.$store.state.engagements + 1
          );
        }
      });

    window.Echo.channel("notiChannel") //Should be Channel Name
      .listen("NotiEvent", (e) => {
        if (
          e.action.email == this.$storage.get("auth").user.email &&
          e.action.type == "incommingText"
        ) {
          var count = $("#bel2").text();
          count = eval(count) + 1;
          $("#bel2").text(count);
          this.getSMS();
        }
      });

    window.Echo.channel("Facebook_Message") //Should be Channel Name
      .listen("NewFacebookMessageEvent", (data) => {
        console.log(data);
        if (data.data == "newlead") {
          this.getActiveleads();
        }
        if ($(".mark").length == 0) {
          $("#marknewmessg" + data.lead).append(
            '<p class="mark" ><span></span></p>'
          );
        }
        this.getFBMessages();
      });

    window.Echo.channel("notiChannel") //Should be Channel Name
      .listen("NotiEvent", (e) => {
        this.getEmails();
        console.log(e);
      });

    // Scroll Logic for SMS and Emails Notifications
    // notification_type?/:notification_id
    this.notification_type = this.$route.params.notification_type;
    this.notification_id = this.$route.params.notification_id;
    if (this.notification_type == 2) {
      this.isMailContainerActive = false;
      this.isSmsContainerActive = false;
      this.isFbMessageContainerActive = true;
    }
    if (this.notification_type == 3) {
      this.isMailContainerActive = true;
      this.isSmsContainerActive = false;
      this.isFbMessageContainerActive = false;
    }
    if (this.notification_type == 4) {
      this.isMailContainerActive = false;
      this.isSmsContainerActive = true;
      this.isFbMessageContainerActive = false;
    }
    let v = this;
    $('a[href="#text"]').on("shown.bs.tab", function () {
      setTimeout(() => {
        v.scrollToEndSMSContainer();
      });
    });
    $(".sms").click(function () {
      setTimeout(() => {
        v.scrollToEndSMSContainer();
      });
    });
  },

  updated() {},

  methods: {
    tabOpen(name = ''){
      console.log({name});
      switch (name) {
        case 'email':
          this.isMailContainerActive = true;
          this.isSmsContainerActive = false;
          this.isFbMessageContainerActive = false;
          break;
        case 'sms':
          this.isMailContainerActive = false;
          this.isSmsContainerActive = true;
          this.isFbMessageContainerActive = false;
          break;
        case 'fb_message':
          this.isMailContainerActive = false;
          this.isSmsContainerActive = false;
          this.isFbMessageContainerActive = true;
          break;
      
        default:
          break;
      }
    },
    stopRespponseTimeCounter() {
      axios
        .get("uts/" + this.$route.params.id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadtextTemplate() {
      var template = this.smstemp.body;
      if (template != "") {
        if (this.lead_detail.v_year == null) {
          this.lead_detail.v_year = "";
        }
        if (this.lead_detail.v_make == null) {
          this.lead_detail.v_make = "";
        }
        if (this.lead_detail.v_model == null) {
          this.lead_detail.v_model = "";
        }
        if (this.lead_detail.v_trim == null) {
          this.lead_detail.v_trim = "";
        }

        if (this.lead_detail.assigned_user_str == null) {
          this.lead_detail.assigned_user_str = "";
        }

        template = template.replace(
          /%CUSTOMER_NAME%/g,
          this.lead_detail.c_first_name + " " + this.lead_detail.c_last_name
        );
        template = template.replace(/%VEHICLE_YEAR%/g, this.lead_detail.v_year);

        template = template.replace(
          /%VEHICLE_MODEL%/g,
          this.lead_detail.v_model
        );
        template = template.replace(/%VEHICLE_TRIM%/g, this.lead_detail.v_trim);
        template = template.replace(/%VEHICLE_MAKE%/g, this.lead_detail.v_make);
        template = template.replace(/%DEALER_NAME%/g, this.lead_detail.dl_name);
        template = template.replace(
          /%SALES_PERSON%/g,
          this.lead_detail.assigned_user_str
        );
        template = template.replace(/%BDC%/g, this.lead_detail.bdc_users_str);
        template = template.replace(
          /%SALES_PERSON_EMAIL_SIGNATURE%/g,
          this.lead_detail.user_signature
        );
        template = template.replace(/(<([^>]+)>)/gi, "");

        // if (
        //   this.lead_detail.v_make != null &&
        //   this.lead_detail.v_make != undefined &&
        //   this.lead_detail.v_make != "undefined" &&
        //   this.lead_detail.v_make != ""
        // ) {
        //   this.emial_temp_.ut_content = this.emial_temp_.ut_content.replace(
        //     /%VEHICLE_MAKE%/g,
        //     this.lead_detail.v_make
        //   );
        // }
        // if (
        //   this.lead_detail.v_model != null &&
        //   this.lead_detail.v_model != undefined &&
        //   this.lead_detail.v_model != "undefined" &&
        //   this.lead_detail.v_model != ""
        // ) {
        //   this.emial_temp_.ut_content = this.emial_temp_.ut_content.replace(
        //     /%VEHICLE_MODEL%/g,
        //     this.lead_detail.v_model
        //   );
        // }
        // /*if (this.lead_detail.v_new_used_status != null && this.lead_detail.v_new_used_status != undefined && this.lead_detail.v_new_used_status != 'undefined' && this.lead_detail.v_new_used_status != '') {
        //             this.emial_temp_.ut_content = this.emial_temp_.ut_content.replace(/%VEHICLE_CONDITION%/g, this.lead_detail.v_new_used_status)
        //         }*/
        // if (
        //   this.lead_detail.v_trim != null &&
        //   this.lead_detail.v_trim != undefined &&
        //   this.lead_detail.v_trim != "undefined" &&
        //   this.lead_detail.v_trim != ""
        // ) {
        //   this.emial_temp_.ut_content = this.emial_temp_.ut_content.replace(
        //     /%VEHICLE_TRIM%/g,
        //     this.lead_detail.v_trim
        //   );
        // }
        // if (
        //   this.lead_detail.dl_name != null &&
        //   this.lead_detail.dl_name != undefined &&
        //   this.lead_detail.dl_name != "undefined" &&
        //   this.lead_detail.dl_name != ""
        // ) {
        //   this.emial_temp_.ut_content = this.emial_temp_.ut_content.replace(
        //     /%DEALER_NAME%/g,
        //     this.lead_detail.dl_name
        //   );
        // }
        // if (
        //   this.lead_detail.dl_name != null &&
        //   this.lead_detail.dl_name != undefined &&
        //   this.lead_detail.dl_name != "undefined" &&
        //   this.lead_detail.dl_name != ""
        // ) {
        //   this.emial_temp_.ut_content = this.emial_temp_.ut_content.replace(
        //     /%DEALER_NAME%/g,
        //     this.lead_detail.dl_name
        //   );
        // }
        // if (
        //   this.lead_detail.assigned_user_str != null &&
        //   this.lead_detail.assigned_user_str != undefined &&
        //   this.lead_detail.assigned_user_str != "undefined" &&
        //   this.lead_detail.assigned_user_str != ""
        // ) {
        //   this.emial_temp_.ut_content = this.emial_temp_.ut_content.replace(
        //     /%SALES_PERSON%/g,
        //     this.lead_detail.assigned_user_str
        //   );
        // }
        // this.emial_temp_.ut_content = this.emial_temp_.ut_content.replace(
        //   /%BDC%/g,
        //   ""
        // );
        // if (
        //   this.lead_detail.user_signature != null &&
        //   this.lead_detail.user_signature != undefined &&
        //   this.lead_detail.user_signature != "undefined" &&
        //   this.lead_detail.user_signature != ""
        // ) {
        //   this.emial_temp_.ut_content = this.emial_temp_.ut_content.replace(
        //     /%SALES_PERSON_EMAIL_SIGNATURE%/g,
        //     this.lead_detail.user_signature
        //   );
        // }
        this.msg_.body = template;
      }
    },
    getSmsTemplates() {
      axios
        .get("get_smstemplates", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.sms_templates = res.data.success;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onFileChange(e, type) {
      console.log(e.target.files[0]);
      this.file = e.target.files[0];
      if (type == "sms") {
        this.sendSMS();
      }
      if (type == "facebook") {
        this.sendfbMessage();
      }
    },
    getFBMessages(read = false) {
      console.log(this.lead_detail.l_customer_id);
      let params = {
        cus_id: this.lead_detail.c_id,
      };

      if(read === true){
        params.module = "chat";
      }

      axios
        .post(
          "getFbMessages",params,
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            const data = res.data;
            if (data.success) {
              this.FbMessages = data.success;
              if(read === true){
                this.FbMessagesUnread = 0;
              }else{
                this.FbMessagesUnread = data.unread;
              }
              let vm = this;
              setTimeout(function () {
                vm.scrollToEndFbContainer();
                $("#fb_message").addClass("tab-pane");
              }, 1000);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendfbMessage() {
      this.fbdisablebtn = true;
      this.btnLoader = true;
      const config = {
        headers: {
          Authorization: "Bearer " + this.$storage.get("auth").token,
        },
      };
      let formData = new FormData();
      formData.append("cus_id", this.lead_detail.c_id);
      formData.append("message", this.msg_.fbmessage);
      formData.append("file", this.file);
      formData.append("lead_id", this.lead_detail.l_id);
      axios
        .post("/sendFBMessage", formData, config)
        .then((res) => {
          console.log(res);
          this.file = "";
          this.btnLoader = false;
          const data = res.data;
          if (data.success) {
            this.$store.commit(
              "updateTouchesCount",
              this.$store.state.touches + 1
            );
            this.msg_.fbmessage = "";
            this.getFBMessages();
            this.fbdisablebtn = false;
            this.stopRespponseTimeCounter();
            this.$root.$emit(
              "action",
              "update_touch_status",
              data.lead_touch_resp_time
            );
          }
          if (data.success == false) {
            this.$toast.open({
              position: "top-right",
              message: data.message,
              type: "info",
            });
          }
        })
        .catch((error) => {
          this.fbdisablebtn = false;
          this.btnLoader = true;
          alert(error);
        });
    },
    getSmtpEmails() {
      axios
        .post(
          "receiveEmails",
          {
            cus_id: this.lead_detail.c_id,
            email: this.lead_detail.c_email,
            lead_id: this.$route.params.id,
            cus_name:
              this.lead_detail.c_first_name +
              " " +
              this.lead_detail.c_last_name,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            const data = res.data;
            if (data.success) {
              this.getEmails();
              this.$store.commit(
                "updateResponseCount",
                this.$store.state.engagements + 1
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    sendSMS() {
      if (this.msg_.body || this.file) {
        this.disableBtn = true;
        this.btnLoader = true;
        const config = {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        };
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("body", this.msg_.body);
        formData.append(
          "c_name",
          this.lead_detail.c_first_name + " " + this.lead_detail.c_last_name
        );
        formData.append("customer_id", this.lead_detail.l_customer_id);
        formData.append("dealer_id", this.lead_detail.l_dealer_id);
        formData.append("isFirstSms", this.lead_detail.c_is_first_sms);
        formData.append("smsPermission", this.lead_detail.c_sms_permission);
        formData.append("lead_id", this.lead_detail.l_id);
        formData.append("phone", this.lead_detail.c_phone);
        axios
          .post("sendSMS", formData, config)
          .then((res) => {
            if (res.data.error) {
              this.$toast.open({
                position: "top-right",
                message: res.data.error,
                type: "info",
              });
              this.btnLoader = false;
              return false;
            }
            if (res.data.success) {
              this.file = "";
              this.getSMS();

              if (this.lead_detail.l_status < 2) {
                this.updateLeadStatus(2);
              }
              this.$root.$emit("action", "new_activity");
              this.$store.commit(
                "updateTouchesCount",
                this.$store.state.touches + 1
              );
              //this.$root.$emit('action', 'update_touch_status')
              this.$root.$emit(
                "action",
                "update_touch_status",
                res.data.lead_touch_resp_time
              );
              this.msg_.body = "";
              this.lead_detail.c_is_first_sms = 0;
              if (res.data.perm_update == 1) {
                this.lead_detail.c_sms_permission = 1;
              }
              this.disableBtn = false;
              this.btnLoader = false;
            }
          })
          .catch((error) => {
            this.btnLoader = false;
            this.btnLoader = false;
            alert(error.message);
          });
      }
    },
    sendEmail() {
      this.btnLoader = true;
      //console.log(this.email_)
      let formData = new FormData();
      for (var i = 0; i < this.email_.files.length; i++) {
        let file = this.email_.files[i];
        formData.append("files[" + i + "]", file);
      }

      if (this.email_.tags.length > 0 && this.email_.body != "") {
        let emails = "";
        for (let index = 0; index < this.email_.tags.length; index++) {
          if (index > 0) {
            emails += ",";
          }
          emails += this.email_.tags[index].text;
        }
        //console.log(this.lead_detail.c_first_name+' '+this.lead_detail.c_last_name)
        formData.append("body", this.email_.body);
        formData.append("subject", this.email_.subject);
        formData.append("lead_id", this.email_.lead_id);
        formData.append("emails", emails);
        formData.append("c_id", this.lead_detail.c_id);
        formData.append("c_email", this.lead_detail.c_email);
        formData.append(
          "c_name",
          this.lead_detail.c_first_name + " " + this.lead_detail.c_last_name
        );

        axios
          .post("send_email", formData, {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
              "content-type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.data.smtpConfigure == false) {
              this.btnLoader = false;
              alert("Please Add Smtp Configuration");
              return false;
            }
            if (res.data.subject == false) {
              this.btnLoader = false;
              this.$toast.open({
                position: "top-right",
                message: "Subject field is required",
                type: "info",
              });
              return false;
            }
            if (res.data.success) {
              this.getEmails();
              if (this.lead_detail.l_status < 2) {
                this.updateLeadStatus(2);
              }
              this.$root.$emit("action", "new_activity");
              this.$store.commit(
                "updateTouchesCount",
                this.$store.state.touches + 1
              );
              //this.$root.$emit('action', 'update_touch_status')
              this.$root.$emit(
                "action",
                "update_touch_status",
                res.data.lead_touch_resp_time
              );

              this.email_.body = "";
              this.tiny_editor.setContent("");
              this.email_.files = "";
              this.btnLoader = false;
            } else {
              this.btnLoader = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.btnLoader = false;
          });
      } else {
        this.btnLoader = false;
      }
    },
    updateLeadStatus(status) {
      axios
        .post(
          "update_lead_status",
          { status: status, lead_id: this.$route.params.id },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          const data = res.data;
          if (data.success) {
            this.lead_detail.l_status = 2;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSMS(read = false) {
      let url = "getSMS/" + this.lead_detail.l_customer_id;
      if(read === true){
        url = url + "?module=chat";
      }
      //console.log(this.lead_detail)
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            const data = res.data;
            this.allMessages = data.success;
            if(read === true){
              this.MessagesUnread = 0;
            }else{
              this.MessagesUnread = data.unread;
            }
            setTimeout(() => {
              this.scrollToEndSMSContainer();
            });
            this.$nextTick(() => {
              // const el = document.getElementById(
              //   "msg-id-" + this.notification_id
              // );
              setTimeout(function () {
                let noti_bc = document
                  .querySelector("msg-id-" + this.notification_id);
                  if(noti_bc){
                    noti_bc.scrollIntoView({ behavior: "smooth" });
                  }
              }, 2000);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getEmailTemplates() {
      axios
        .get("get_templates/1", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.email_templates = res.data.success;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadTemplate() {
      //console.log(this.lead_detail)
      if (this.emial_temp_ != "") {
        this.emial_temp_.ut_content = this.emial_temp_.ut_content.replace(
          /%CUSTOMER_NAME%/g,
          this.lead_detail.c_first_name + " " + this.lead_detail.c_last_name
        );
        if (
          this.lead_detail.v_year != null &&
          this.lead_detail.v_year != undefined &&
          this.lead_detail.v_year != "undefined" &&
          this.lead_detail.v_year != ""
        ) {
          this.emial_temp_.ut_content = this.emial_temp_.ut_content.replace(
            /%VEHICLE_YEAR%/g,
            this.lead_detail.v_year
          );
        }
        if (
          this.lead_detail.v_make != null &&
          this.lead_detail.v_make != undefined &&
          this.lead_detail.v_make != "undefined" &&
          this.lead_detail.v_make != ""
        ) {
          this.emial_temp_.ut_content = this.emial_temp_.ut_content.replace(
            /%VEHICLE_MAKE%/g,
            this.lead_detail.v_make
          );
        }
        if (
          this.lead_detail.v_model != null &&
          this.lead_detail.v_model != undefined &&
          this.lead_detail.v_model != "undefined" &&
          this.lead_detail.v_model != ""
        ) {
          this.emial_temp_.ut_content = this.emial_temp_.ut_content.replace(
            /%VEHICLE_MODEL%/g,
            this.lead_detail.v_model
          );
        }
        /*if (this.lead_detail.v_new_used_status != null && this.lead_detail.v_new_used_status != undefined && this.lead_detail.v_new_used_status != 'undefined' && this.lead_detail.v_new_used_status != '') {
                    this.emial_temp_.ut_content = this.emial_temp_.ut_content.replace(/%VEHICLE_CONDITION%/g, this.lead_detail.v_new_used_status)
                }*/
        if (
          this.lead_detail.v_trim != null &&
          this.lead_detail.v_trim != undefined &&
          this.lead_detail.v_trim != "undefined" &&
          this.lead_detail.v_trim != ""
        ) {
          this.emial_temp_.ut_content = this.emial_temp_.ut_content.replace(
            /%VEHICLE_TRIM%/g,
            this.lead_detail.v_trim
          );
        }
        if (
          this.lead_detail.dl_name != null &&
          this.lead_detail.dl_name != undefined &&
          this.lead_detail.dl_name != "undefined" &&
          this.lead_detail.dl_name != ""
        ) {
          this.emial_temp_.ut_content = this.emial_temp_.ut_content.replace(
            /%DEALER_NAME%/g,
            this.lead_detail.dl_name
          );
        }
        if (
          this.lead_detail.dl_name != null &&
          this.lead_detail.dl_name != undefined &&
          this.lead_detail.dl_name != "undefined" &&
          this.lead_detail.dl_name != ""
        ) {
          this.emial_temp_.ut_content = this.emial_temp_.ut_content.replace(
            /%DEALER_NAME%/g,
            this.lead_detail.dl_name
          );
        }
        if (
          this.lead_detail.assigned_user_str != null &&
          this.lead_detail.assigned_user_str != undefined &&
          this.lead_detail.assigned_user_str != "undefined" &&
          this.lead_detail.assigned_user_str != ""
        ) {
          this.emial_temp_.ut_content = this.emial_temp_.ut_content.replace(
            /%SALES_PERSON%/g,
            this.lead_detail.assigned_user_str
          );
        }
        this.emial_temp_.ut_content = this.emial_temp_.ut_content.replace(
          /%BDC%/g,
          ""
        );
        if (
          this.lead_detail.user_signature != null &&
          this.lead_detail.user_signature != undefined &&
          this.lead_detail.user_signature != "undefined" &&
          this.lead_detail.user_signature != ""
        ) {
          this.emial_temp_.ut_content = this.emial_temp_.ut_content.replace(
            /%SALES_PERSON_EMAIL_SIGNATURE%/g,
            this.lead_detail.user_signature
          );
        }

        this.tiny_editor.setContent(this.emial_temp_.ut_content);
        this.email_.body = this.emial_temp_.ut_content;
        this.email_.subject = this.emial_temp_.ut_subject;
      }
    },
    scrollToEndFbContainer: function () {
      var container = this.$el.querySelector("#CommunicationFBSection");
      container.scrollTop = container.scrollHeight;
    },
    scrollToEndSMSContainer: function () {
      var container = this.$el.querySelector("#CommunicationSmsSection");
      container.scrollTop = container.scrollHeight;
    },
    getEmails() {
      axios
        .get("getEmails/" + this.lead_detail.l_customer_id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            const data = res.data;
            this.allEmails = data.success;
            this.$nextTick(() => {
              const el = document.getElementById(
                "mail-id-" + this.notification_id
              );
              el.scrollIntoView({ block: "center", behavior: "smooth" });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkTag(obj) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(obj.tag.text)) {
        obj.addTag();
      }
    },
    handleFilesUpload() {
      this.email_.files = this.$refs.files.files;
    },
  },
};
</script>

<style scoped>
.tags-input {
  max-width: 100% !important;
}
/* CSS for the badge */
.tab-badge-left {
  position: absolute;
  top: -15px;
  left: 0px;
  background-color: #3151a1;
  color: white;
  border: inset;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
}

.tab-badge-right {
  position: absolute;
  top: -15px;
  right: 0px;
  background-color: #3151a1;
  color: white;
  border: inset;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
}

.span-badge {
  background-color: #3151a1;
  color: white;
  border: inset;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
}
#email .after-email {
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
  margin: 3px 0;
}
</style>
<style>
.tox .tox-tbtn svg {
  fill: #a2a8b8 !important;
}
.image-upload > input {
  display: none;
}
.image-upload label {
  position: absolute;
  bottom: 8px;
  right: 65px;
  z-index: 999;
  cursor: pointer;
  top: 14px;
}
.message-box .form-control {
  background-color: transparent !important;
  padding-right: 85px;
}
.lightbox__thumbnail img {
  width: 200px;
}
.lightbox {
  z-index: 99999 !important;
}

.light_input_box{
  margin: 5px 0px !important;
  border-radius: 5px;
  background-color: #F6F7FA;
}

.light_input_self{
  border: 1px solid #f6f7fa !important;
}
</style>
