<template>
  <DashboardContainer>
    <div
      class="newoverlaydiv"
      style="
        z-index: 1000;
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #ffffff;
      "
    ></div>
    <div class="leaddetails-left">
      <div class="left-container">
        <lead-profile :lead_detail="LDetail" :customer_id="LDetail.c_id" />
      </div>
      <vehicle-of-interest :lead_detail="LDetail" />
    </div>
    <!-- leaddetails-mid -->
    <div class="leaddetails-mid">
      <lead-tabs
        :customer_id="LDetail.c_id"
        :lead_type="LDetail.l_type"
        :vehicle_id="LDetail.l_vehicle_id"
        :lead_status="LDetail.l_status"
        :lead_sub_status="LDetail.sub_status"
        :customer_phone="LDetail.c_phone"
        :lead_created="LDetail.l_created"
        v-if="LDetail"
        :lead_touch_status="LDetail.l_touch_status"
        :lead_touch_response_time="LDetail.l_touch_time"
        :lead_touch_time_diff="LDetail.l_touch_time_diff"
        :lead_source="LDetail.l_source"
      />
      <notes :lead_detail="LDetail" />
      <communication :lead_detail="LDetail" />
      <activity-area />
    </div>
    <!-- leaddetails-mid -->

    <!-- leaddetails-right -->
    <div class="leaddetails-right">
      <appointments
        :employeesList="allEmployees"
        :lead_status="LDetail.l_status"
        :defaultAssignTo="assignTo"
      />
      <tasks
        :employeesList="allEmployees"
        :lead_status="LDetail.l_status"
        :defaultAssignTo="assignTo"
      />
      <lead-alerts :lead_detail="LDetail" />
      <!-- <sticky-notes /> -->
    </div>

    <!-- <lead-detail-grid :username="allEmployees"/> -->
    <!-- <AddNewLead/> -->
    <div
      class="modal fade"
      id="leadservice"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
      data-keyboard="false"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Attention</h5>
          </div>

          <div class="modal-body">
            <p style="margin-bottom: 20px">
              {{ message }}
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" @click="redirectIt" class="rem-btn close">
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  </DashboardContainer>
</template>

<script>
//import LeadDetailContainer from '../containers/LeadDetailContainer'
import DashboardContainer from "../containers/DashboardContainer";
import LeadProfile from "../components/leads/LeadProfile";
import VehicleOfInterest from "../components/leads/VehicleOfInterest";

import LeadTabs from "../components/leads/LeadTabs";
import ActivityArea from "../components/leads/ActivityArea";
import Communication from "../components/leads/Communication";

import Appointments from "../components/leads/Appointments";
import Tasks from "../components/leads/Tasks";
import Notes from "../components/leads/Notes";
import LeadAlerts from "../components/alerts/LeadAlerts";
//import StickyNotes from '../components/leads/StickyNotes'
import $ from "jquery";
import axios from "axios";
import Swal from "sweetalert2";
//import moment from 'moment'
export default {
  name: "LeadDetail",
  beforeCreate: function () {
    var auth = this.$storage.get("auth");
    if (!auth) {
      this.$router.push("/login");
    } else {
      if (!auth.token) {
        this.$router.push("/login");
      }
    }
  },
  components: {
    DashboardContainer,
    LeadProfile,
    VehicleOfInterest,

    LeadTabs,
    ActivityArea,
    Communication,

    Appointments,
    Tasks,
    Notes,
    LeadAlerts,
    //StickyNotes
  },
  data() {
    return {
      allEmployees: "",
      assignTo: "",
      LDetail: {},
      message: "The lead that belongs to this notification has been deleted",
    };
  },
  watch: {
    "$route.params.id": function () {
      this.$router.go();
    },
  },
  created() {
    this.getAllEmployees();
    this.getLeadDetail();
    this.updateLeadIsView();
  },
  methods: {
    redirectIt() {
      $("#leadservice").modal("hide");
      this.$router.push("/leads");
    },
    getAllEmployees() {
      axios
        .get("all_employees", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          console.log(data);
          if (data.success) {
            this.allEmployees = data.success;
            this.assignTo = data.user_id;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLeadDetail() {
      axios
        .get("lead_detail/" + this.$route.params.id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.status == "noprermission") {
            $(".newoverlaydiv").show();
            this.LDetail = {};
            this.message = res.data.message;
            Swal.fire({
              text: this.message,
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonText: "Ok",
              confirmButtonColor: "#19cd9d",
              customClass: {
                confirmButton: "rem-btn", //insert class here
              },
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                if (this.$route.name == "LeadDetail") {
                  this.$router.push("/leads");
                }
              }
            });
            // $("#leadservice").modal("show");
            // $("#leadservice").appendTo("body");
          } else {
            $(".newoverlaydiv").hide();
          }
          if (res.data.success) {
            this.$store.commit("updateModal", true);
            this.LDetail = res.data.success;

            //console.log(this.LDetail)
            if (
              this.LDetail.l_touch_time == null ||
              this.LDetail.l_touch_time == undefined ||
              this.LDetail.l_touch_time == "" ||
              this.LDetail.l_touch_time == "undefined"
            ) {
              //alert("null")
            } else {
              //
            }
            this.$root.$emit("lead_detail_status", "updated");
            this.$store.commit("updateTouchesCount", this.LDetail.l_touches);

            this.$store.commit(
              "updateResponseCount",
              this.LDetail.l_engagements
            );
            //console.log(this.LDetail)
          }
          if (res.data.success == false) {
            this.$router.push("/leads");
          }
          if (res.data.success == "") {
            Swal.fire({
              text: this.message,
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonText: "Ok",
              confirmButtonColor: "#19cd9d",
               customClass: {
                confirmButton: "rem-btn", //insert class here
              },
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                if (this.$route.name == "LeadDetail") {
                  this.$router.push("/leads");
                }
              }
            });
            // $("#leadservice").modal("show");
            // $("#leadservice").appendTo("body");
          }
          if (this.LDetail.l_is_view == 0 && this.LDetail.leadcount > 1) {
            this.$root.$emit("action", "customerAlreadyexits");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateLeadIsView() {
      axios
        .get("isview/" + this.$route.params.id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            console.log("status updated");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
