<template>
  <!-- Activity-area -->
  <div class="activity-area">
    <div class="accordion" id="accordionExample">
      <div class="card">
        <div class="card-header" id="headingOne1">
          <div class="mb-0">
            <button
              class="btn btn-link"
              type="button"
              data-toggle="collapse"
              data-target="#collapseOne1"
            >
              <div class="detais-title">
                <h3>
                  <img
                    class="img-fluid arrow-down"
                    src="../../assets/img/arrow-down.svg"
                    alt=""
                  />
                  Activity Log
                  <!-- <a class="add-appoi" href="#"> View all</a> -->
                </h3>
              </div>
            </button>
          </div>
        </div>

        <div
          id="collapseOne1"
          class="collapse show"
          aria-labelledby="headingOne1"
          data-parent="#accordionExample"
        >
          <div class="card-body">
            <div class="panel-group" id="accordion">
              <simplebar class="all-activity">
                <div
                  class="panel panel-default"
                  v-for="(activity, i) in activities"
                  :key="activity.al_id"
                >
                  <!-- v-if="activity.al_type < 3" -->
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a class="p-0" v-on:click="activityDetail(activity, i)">
                        <div class="activity">
                          <div class="activity-icon">
                            <svg
                              v-if="
                                activity.al_type == 1 || activity.al_type == 2
                              "
                              xmlns="http://www.w3.org/2000/svg"
                              width="23"
                              height="23"
                              style="background: #dbe5ff; border-radius: 47%"
                              viewBox="-4 -3 18 17"
                              fill="none"
                            >
                              <g clip-path="url(#clip0)">
                                <path
                                  d="M3.41634 1.25C3.41634 0.973858 3.19248 0.75 2.91634 0.75C2.6402 0.75 2.41634 0.973858 2.41634 1.25H3.41634ZM7.58301 1.25C7.58301 0.973858 7.35915 0.75 7.08301 0.75C6.80687 0.75 6.58301 0.973858 6.58301 1.25H7.58301ZM1.66634 2.58333H2.91634V1.58333H1.66634V2.58333ZM2.91634 2.58333H7.08301V1.58333H2.91634V2.58333ZM7.08301 2.58333H8.33301V1.58333H7.08301V2.58333ZM8.33301 9.08333H1.66634V10.0833H8.33301V9.08333ZM1.33301 8.75V4.16667H0.333008V8.75H1.33301ZM1.33301 4.16667V2.91667H0.333008V4.16667H1.33301ZM8.66634 2.91667V4.16667H9.66634V2.91667H8.66634ZM8.66634 4.16667V8.75H9.66634V4.16667H8.66634ZM0.833008 4.66667H9.16634V3.66667H0.833008V4.66667ZM3.41634 2.08333V1.25H2.41634V2.08333H3.41634ZM7.58301 2.08333V1.25H6.58301V2.08333H7.58301ZM1.66634 9.08333C1.48225 9.08333 1.33301 8.93409 1.33301 8.75H0.333008C0.333008 9.48638 0.929962 10.0833 1.66634 10.0833V9.08333ZM8.33301 10.0833C9.06939 10.0833 9.66634 9.48638 9.66634 8.75H8.66634C8.66634 8.9341 8.5171 9.08333 8.33301 9.08333V10.0833ZM8.33301 2.58333C8.5171 2.58333 8.66634 2.73257 8.66634 2.91667H9.66634C9.66634 2.18029 9.06939 1.58333 8.33301 1.58333V2.58333ZM1.66634 1.58333C0.929962 1.58333 0.333008 2.18029 0.333008 2.91667H1.33301C1.33301 2.73257 1.48225 2.58333 1.66634 2.58333V1.58333Z"
                                  fill="#3151A1"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0">
                                  <rect width="10" height="10" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <img
                              v-if="
                                activity.al_type == 3 || activity.al_type == 6
                              "
                              class="img-fluid"
                              src="../../assets/img/Call (1).svg"
                              alt=""
                            />
                            <img
                              v-if="activity.al_type == 5"
                              class="img-fluid"
                              src="../../assets/img/Call.svg"
                              alt=""
                            />
                            <span
                              v-if="
                                activity.al_type == 4 ||
                                activity.al_type == 7 ||
                                activity.al_type == 8 ||
                                activity.al_type == 9 ||
                                activity.al_type == 10 ||
                                activity.al_type == 11
                              "
                            >
                              <label class="detecheck">
                                <input type="checkbox" checked />
                                <span class="checactive"></span>
                              </label>
                            </span>
                          </div>
                          <div class="activity-text">
                            <p>{{ activity.al_details }}</p>
                          </div>

                          <div class="Activity-time">
                            <p>
                              {{ activity.al_created }}
                            </p>
                          </div>
                        </div>
                      </a>
                    </h4>
                  </div>
                  <div :id="'collapse' + i" class="panel-collapse collapse">
                    <div class="panel-body">
                      <div class="row">
                        <div
                          class="col-md-6 detail-activity"
                          v-if="activity_detail.a_date"
                        >
                          <div>Date:</div>
                          <div>{{ activity_detail.a_date }}</div>
                        </div>
                        <div
                          class="col-md-6 detail-activity"
                          v-if="activity_detail.a_time"
                        >
                          <div>Time:</div>
                          <div>{{ activity_detail.a_time }}</div>
                        </div>
                        <div
                          class="col-md-6 detail-activity"
                          v-if="activity_detail.a_by"
                        >
                          <div>By:</div>
                          <div>{{ activity_detail.a_by }}</div>
                        </div>
                        <div
                          class="col-md-6 detail-activity"
                          v-if="activity_detail.a_for"
                        >
                          <div>
                            For:
                            <!-- <span v-if="activity_detail.al_type == 1 || activity_detail.al_type == 2">Assigned to</span> -->
                            <!-- <span v-if="activity_detail.al_type == 1">{{activity_detail.ap_date}}</span>
                                                    <span v-if="activity_detail.al_type == 1">{{activity_detail.ap_date}}</span> -->
                          </div>
                          <div>{{ activity_detail.a_for }}</div>
                        </div>
                        <div
                          class="col-md-6 detail-activity"
                          v-if="activity_detail.a_type"
                        >
                          <div>Status:</div>
                          <div>{{ activity_detail.a_type }}</div>
                        </div>
                        <div
                          class="col-md-6 detail-activity"
                          v-if="activity_detail.a_status"
                        >
                          <div>Status:</div>
                          <div>{{ activity_detail.a_status }}</div>
                        </div>
                        <div
                          class="col-md-12 detail-activity"
                          v-if="activity_detail.a_desc"
                        >
                          <div>Description:</div>
                          <div v-html="activity_detail.a_desc"></div>
                        </div>
                        <div
                          class="col-md-12 detail-activity"
                          v-if="activity_detail.a_content"
                        >
                          <div>Content:</div>
                          <div v-html="activity_detail.a_content"></div>
                        </div>
                        <div
                          class="col-md-12 detail-activity"
                          v-if="activity_detail.a_why_lost"
                        >
                          <div>Why Lost:</div>
                          <div v-html="activity_detail.a_why_lost"></div>
                        </div>
                        <div
                          class="col-md-12 detail-activity"
                          v-if="activity_detail.a_why_lost_comment"
                        >
                          <div>Comment:</div>
                          <div
                            v-html="activity_detail.a_why_lost_comment"
                          ></div>
                        </div>

                        <!-- <div class="col-md-12 details-profile pt-0 pb-0">
                                                <table class="table">
                                                    <tbody>
                                                        <tr class="col-md-6">
                                                            <td>Date</td>
                                                            <td>
                                                                <span v-if="activity_detail.al_type == 1">{{activity_detail.ap_date}}</span>
                                                                <span v-if="activity_detail.al_type == 2">{{activity_detail.tsk_date}}</span>
                                                            </td>
                                                        </tr>
                                                        <tr class="col-md-6">
                                                            <td>Assigned to</td>
                                                            <td>
                                                                <span v-if="activity_detail.al_type == 1">{{activity_detail.ap_to}}</span>
                                                                <span v-if="activity_detail.al_type == 2">{{activity_detail.tsk_to}}</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="col-md-6 details-profile pt-0 pb-0">
                                                <table class="table">
                                                    <tbody>
                                                        <tr>
                                                            <td>Time</td>
                                                            <td>
                                                                <span v-if="activity_detail.al_type == 1">{{activity_detail.ap_time}}</span>
                                                                <span v-if="activity_detail.al_type == 2">{{activity_detail.tsk_time}}</span>
                                                            </td>
                                                        </tr>
                                                        <tr v-if="activity_detail.al_type == 1">
                                                            <td>Status</td>
                                                            <td>Scheduled</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="col-md-6 details-profile pt-0 pb-0">
                                                <table class="table">
                                                    <tbody>
                                                        <tr>
                                                            <td>Description</td>
                                                            <td>
                                                                <span v-if="activity_detail.al_type == 1">{{activity_detail.ap_description}}</span>
                                                                <span v-if="activity_detail.al_type == 2">{{activity_detail.ta_name}}</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div> -->
                      </div>
                    </div>
                  </div>

                  <!-- <div class="activity" v-if="activity.al_type > 2">
                                    <div class="activity-icon">
                                        <img v-if="activity.al_type ==1" class="img-fluid" src="../../assets/img/Call.svg" alt="">
                                        <img v-if="activity.al_type ==2" class="img-fluid" src="../../assets/img/Call (1).svg" alt="">
                                        <span v-if="activity.al_type !=1 && activity.al_type !=2">
                                            <label class="detecheck">
                                            <input type="checkbox" checked>
                                            <span class="checactive"></span>
                                            </label>
                                        </span>
                                    </div>
                                    <div class="activity-text">
                                        <p>{{activity.al_details}}</p>
                                    </div>

                                    <div class="Activity-time">
                                        <p>{{activity.al_created | formatDate('hh:mm A')}}</p>
                                    </div>
                                </div> -->
                </div>

                <!-- <div class="activity"  v-for="activity in activities" :key="activity.al_id" v-on:click="activityDetail(activity)">
                                <div class="activity-icon">
                                    <svg v-if="activity.al_type ==1 || activity.al_type ==2" xmlns="http://www.w3.org/2000/svg" width="23" height="23" style="background: #dbe5ff;border-radius: 47%;" viewBox="-4 -3 18 17" fill="none"><g clip-path="url(#clip0)"><path d="M3.41634 1.25C3.41634 0.973858 3.19248 0.75 2.91634 0.75C2.6402 0.75 2.41634 0.973858 2.41634 1.25H3.41634ZM7.58301 1.25C7.58301 0.973858 7.35915 0.75 7.08301 0.75C6.80687 0.75 6.58301 0.973858 6.58301 1.25H7.58301ZM1.66634 2.58333H2.91634V1.58333H1.66634V2.58333ZM2.91634 2.58333H7.08301V1.58333H2.91634V2.58333ZM7.08301 2.58333H8.33301V1.58333H7.08301V2.58333ZM8.33301 9.08333H1.66634V10.0833H8.33301V9.08333ZM1.33301 8.75V4.16667H0.333008V8.75H1.33301ZM1.33301 4.16667V2.91667H0.333008V4.16667H1.33301ZM8.66634 2.91667V4.16667H9.66634V2.91667H8.66634ZM8.66634 4.16667V8.75H9.66634V4.16667H8.66634ZM0.833008 4.66667H9.16634V3.66667H0.833008V4.66667ZM3.41634 2.08333V1.25H2.41634V2.08333H3.41634ZM7.58301 2.08333V1.25H6.58301V2.08333H7.58301ZM1.66634 9.08333C1.48225 9.08333 1.33301 8.93409 1.33301 8.75H0.333008C0.333008 9.48638 0.929962 10.0833 1.66634 10.0833V9.08333ZM8.33301 10.0833C9.06939 10.0833 9.66634 9.48638 9.66634 8.75H8.66634C8.66634 8.9341 8.5171 9.08333 8.33301 9.08333V10.0833ZM8.33301 2.58333C8.5171 2.58333 8.66634 2.73257 8.66634 2.91667H9.66634C9.66634 2.18029 9.06939 1.58333 8.33301 1.58333V2.58333ZM1.66634 1.58333C0.929962 1.58333 0.333008 2.18029 0.333008 2.91667H1.33301C1.33301 2.73257 1.48225 2.58333 1.66634 2.58333V1.58333Z" fill="#3151A1"/></g><defs><clipPath id="clip0"><rect width="10" height="10" fill="white"/></clipPath></defs></svg>
                                    <img v-if="activity.al_type ==3" class="img-fluid" src="../../assets/img/Call (1).svg" alt="">
                                    <img v-if="activity.al_type ==5" class="img-fluid" src="../../assets/img/Call.svg" alt="">
                                    <span v-if="activity.al_type == 4">
                                        <label class="detecheck">
                                        <input type="checkbox" checked>
                                        <span class="checactive"></span>
                                        </label>
                                    </span>
                                </div>
                                <div class="activity-text">
                                    <p>{{activity.al_details}}</p>
                                </div>

                                <div class="Activity-time">
                                    <p>{{activity.al_created | formatDate('hh:mm A')}}</p>
                                </div>
                            </div> -->
                <!-- activity -->
              </simplebar>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Activity Detail Modal -->
    <!-- <div class="modal fade" id="activityDetailModel" tabindex="-1" role="dialog" aria-labelledby="Lead Detail" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{la_.title}}</h5>
                        <a class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"><img class="img-fluid" src="../../assets/img/23. Closex.svg" alt=""></span>
                        </a>
                    </div>
                    <div class="modal-body">
                        <div class="details-modal">
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <td>Date</td>
                                        <td>
                                            <span v-if="activity_detail.al_type == 1">{{activity_detail.ap_date}}</span>
                                            <span v-if="activity_detail.al_type == 2">{{activity_detail.tsk_date}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Time</td>
                                        <td>
                                            <span v-if="activity_detail.al_type == 1">{{activity_detail.ap_time}}</span>
                                            <span v-if="activity_detail.al_type == 2">{{activity_detail.tsk_time}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Assigned to</td>
                                        <td>
                                            <span v-if="activity_detail.al_type == 1">{{activity_detail.ap_to}}</span>
                                            <span v-if="activity_detail.al_type == 2">{{activity_detail.tsk_to}}</span>
                                        </td>
                                    </tr>
                                    <tr v-if="activity_detail.al_type == 1">
                                        <td>Status</td>
                                        <td>Scheduled</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="detals-footer">
                        <h5>Description</h5>
                        <div class="activity-icon">
                            <img class="img-fluid" src="../../assets/img/Call.svg" alt="">
                        </div>
                        <div class="activity-text">
                            <p>
                                <span v-if="activity_detail.al_type == 1">{{activity_detail.ap_description}}</span>
                                <span v-if="activity_detail.al_type == 2">{{activity_detail.ta_name}}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

    <!-- Activity Detail Modal -->
  </div>
  <!-- Activity-area-->
</template>
<script>
import axios from "axios";
import $ from "jquery";
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
export default {
  name: "ActivityArea",
  components: {
    simplebar,
  },
  data() {
    return {
      activities: {},
      la_: {},
      activity_detail: {},
      current_click: "",
    };
  },
  created() {
    let vm = this;
    this.getActivities();
    this.$root.$on("action", function (action) {
      if (action == "new_activity") {
        vm.getActivities();
      }
    });
  },
  methods: {
    toggleDetail(i) {
      $(".panel-collapse").removeClass("show");
      if ($("#collapse" + i).hasClass("show")) {
        $("#collapse" + i).removeClass("show");
      } else {
        $("#collapse" + i).addClass("show");
      }
    },
    activityDetail(activity, i) {
      this.activity_detail = {};
      //console.log(activity)
      //if(activity.al_type == 1 || activity.al_type == 2){
      // if(activity.al_type == 1){
      //     this.la_.title = "Appointment Detail"
      // }else if (activity.al_type == 2) {
      //     this.la_.title = "Task"
      // }
      //$("#activityDetailModel").modal('show')

      //$(".panel-collapse").removeClass("show")
      if ($("#collapse" + i).hasClass("show")) {
        $("#collapse" + i).removeClass("show");
      } else {
        $(".panel-collapse").removeClass("show");
        $("#collapse" + i).addClass("show");

        axios
          .post("activity_detail", activity, {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          })
          .then((res) => {
            if (res.data.success) {
              this.activity_detail = {};
              this.activity_detail = res.data.success;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      //}
    },
    getActivities() {
      axios
        .get("activity/" + this.$route.params.id)
        .then((res) => {
          if (res.data.success) {
            const data = res.data;
            this.activities = data.success;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.activity-icon {
  min-height: 24px !important;
  margin-right: 7px;
}
.detail-activity {
  padding: 0px 10px;
}
.detail-activity div {
  color: #6d778e;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  padding: 5px;
  float: left;
}
.detail-activity div:last-child {
  color: #343945;
}
.detail-activity div:first-child {
  min-width: 80px;
}
.Activity-time {
  width: 43%;
}
.activity-area {
  margin-top: 30px;
}
.activity {
  margin-right: 12px;
}
</style>