<template>
  <div>
    <!-- LeadSheet -->
    <div class="left-container" v-if="leads.length > 0">
      <div class="vehicle-interest">
        <div class="accordion" id="accordionExampleLS">
          <div class="card">
            <div class="card-header" id="headingOneLS">
              <div class="mb-0">
                <button
                  class="btn btn-link"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseOneLS"
                >
                  <div class="detais-title">
                    <h3>
                      <img
                        class="img-fluid arrow-down"
                        src="../../assets/img/arrow-down.svg"
                        alt=""
                      />
                      Leadsheets
                    </h3>
                  </div>
                </button>
              </div>
            </div>

            <div
              id="collapseOneLS"
              class="collapse show"
              aria-labelledby="headingOneLS"
              data-parent="#accordionExampleLS"
            >
              <div class="card-body">
                <!-- Previous Leadsheets-table -->
                <div class="Leadsheets-table">
                  <simplebar class="emp-list-scroll" id="myasd">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th title="Date modifie">Modified</th>
                          <th>Status</th>
                          <th>Agent</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="lead in leads" :key="lead.l_id">
                          <td>
                            <a :href="lead.l_id">#{{ lead.l_id }}</a>
                          </td>
                          <td>{{ lead.modified_date }}</td>
                          <td>{{ lead.status }}</td>
                          <td>{{ lead.agent }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </simplebar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- LeadSheet -->

    <!-- Vehicle of Interest -->
    <div class="left-container">
      <div class="vehicle-interest">
        <div class="accordion" id="accordionExampleVOI">
          <div class="card">
            <div class="card-header" id="headingOneVOI">
              <div class="mb-0">
                <button
                  class="btn btn-link"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseOneVOI"
                >
                  <div class="detais-title">
                    <h3>
                      <img
                        class="img-fluid arrow-down"
                        src="../../assets/img/arrow-down.svg"
                        alt=""
                      />
                      Vehicle of Interest
                    </h3>
                  </div>
                </button>
              </div>
            </div>

            <div
              id="collapseOneVOI"
              :class="voi.v_image ? 'collapse show' : 'collapse'"
              aria-labelledby="headingOneVOI"
              data-parent="#accordionExampleVOI"
            >
              <div class="card-body">
                <!-- Vehicle-tab -->
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="Vehicle-tab"
                      data-toggle="tab"
                      href="#Vehicle"
                      role="tab"
                      aria-controls="Vehicle"
                      aria-selected="true"
                      >Vehicle of Interest</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="Trades-tab"
                      data-toggle="tab"
                      href="#Trades"
                      @click="getTrades()"
                      role="tab"
                      aria-controls="Trades"
                      aria-selected="false"
                      >Trades</a
                    >
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="Vehicle"
                    role="tabpanel"
                    aria-labelledby="Vehicle-tab"
                  >
                    <!-- detais-title -->
                    <div class="detais-search">
                      <div class="form-group Vehicle">
                        <template v-if="IsV2 && IsV2TokenExist">
                          <autocomplete
                            ref="autocomplete"
                            method="post"
                            :request-headers="{
                              'Authorization': 'Bearer ' +  $storage.get('auth').user.dealer_autolink_v2_auth_token
                            }"
                            placeholder="Search Inventory"
                            input-class="form-control border-0"
                            :source="
                              'https://api.dealersgear.com/api/crmInventory?limit=20&offset=0&keyword='
                            "
                            results-property="inventory"
                            :results-display="formattedAutocompleteDisplay"
                            @selected="selectedVehicle"
                            @noResults="addVehicleOnNoResult"
                            @results="addVehicleOnNoResult"
                            :showNoResults="false"
                            @clear="clear"
                          >
                          </autocomplete>  
                        </template>
                        <template v-else>
                          <autocomplete
                            ref="autocomplete"
                            placeholder="Search Inventory"
                            input-class="form-control border-0"
                            :source="
                              'https://autolinkme.com/webservices/crm/inventory?id=' +
                              $storage.get('auth').user.dealer_autolink_id +
                              '&limit=20&offset=0&status=active&search_by=value&search_string='
                            "
                            results-property="data"
                            :results-display="formattedAutocompleteDisplay"
                            @selected="selectedVehicle"
                            @noResults="addVehicleOnNoResult"
                            @results="addVehicleOnNoResult"
                            :showNoResults="false"
                            @clear="clear"
                          >
                          </autocomplete>
                        </template>
                        <!-- <input type="search" class="form-control" id="inputAddress23" placeholder="Search Inventory">
                                            <img class="img-fluid Vehi-img" src="../../assets/img/search.svg" alt=""> -->
                      </div>
                    </div>
                    <!-- <carousel :per-page="1" :mouse-drag="true">
                                        <slide v-for="dtl in voi" :key="dtl.v_id"> -->
                    <div class="vehicle-img">
                      <!-- <img class="img-fluid" src="../../assets/img/Rectangle 99.svg" alt=""> -->
                      <img
                        v-if="voi.v_image"
                        class="img-fluid voi-image"
                        v-on:click.stop.prevent="vehicleDetail(voi)"
                        v-bind:src="voi.v_image"
                        alt=""
                      />
                      <img
                        v-else
                        class="img-fluid"
                        v-on:click.stop.prevent="vehicleDetail(voi)"
                        src="../../assets/img/carplaceholder.png"
                      />
                    </div>
                    <div class="vehicle-table">
                      <h4>
                        {{ voi.v_year }} {{ voi.v_make }} {{ voi.v_model }}
                      </h4>
                      <table class="table">
                        <tbody>
                          <tr>
                            <td>Stock#</td>
                            <td>{{ voi.v_stock_no }}</td>
                          </tr>
                          <tr>
                            <td>Miles</td>
                            <td>{{ voi.v_mileage | number("0,0") }}</td>
                          </tr>
                          <tr>
                            <td>VIN</td>
                            <td>{{ voi.v_vin }}</td>
                          </tr>

                          <tr>
                            <td>MSRP</td>
                            <td>
                              <span v-if="voi.v_msrp"
                                >${{ voi.v_msrp | number("0,0") }}</span
                              >
                              <span v-else>N/A</span>
                            </td>
                          </tr>

                          <tr>
                            <td>Selling Price</td>
                            <td>${{ voi.v_price | formatPrice }}</td>
                          </tr>

                          <tr>
                            <td>Special Price</td>
                            <td>${{ voi.v_special_price | formatPrice }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <!-- </slide>
                                    </carousel>     -->
                    <!-- Similir vehicle -->
                    <div
                      class="similir-vehicle"
                      v-if="similarVehicles.length > 0"
                    >
                      <div class="deal-title">
                        <h3>Similar Vehicles In Inventory</h3>
                      </div>
                      <!-- <div class="detais-title">
                                            <h3 class="mb-3">Similar vehicles from inventory</h3>
                                        </div> -->
                      <carousel
                        :per-page="2"
                        :mouse-drag="true"
                        :autoplay="true"
                        :loop="true"
                        :centerMode="true"
                        :autoplayTimeout="5000"
                      >
                        <slide v-for="veh in similarVehicles" :key="veh.id">
                          <a
                            href="#"
                            v-on:click.stop.prevent="vehicleDetail(veh)"
                            ><img class="img-fluid" v-bind:src="veh.img" alt=""
                          /></a>
                        </slide>
                      </carousel>
                      <!-- <div class="vehicle-img" v-for="veh in similarVehicles" :key="veh.year">
                                            <a href="#"><img class="img-fluid" v-bind:src="veh.img" alt=""></a>
                                        </div> -->
                    </div>
                    <!-- Similir vehicle -->
                  </div>
                  <div
                    class="tab-pane fade"
                    id="Trades"
                    role="tabpanel"
                    aria-labelledby="Trades-tab"
                  >
                    <!-- detais-title -->
                    <div class="detais-search">
                      <a
                        href="#"
                        class="trade-btn"
                        data-toggle="modal"
                        data-target="#addTradeModal"
                        ><img
                          class="img-fluid"
                          src="../../assets/img/23. Close.svg"
                          alt=""
                        />
                        Add</a
                      >
                    </div>
                    <carousel :per-page="1" :mouse-drag="true">
                      <slide v-for="trade in trades" :key="trade.lti_id">
                        <div class="vehicle-img">
                          <img
                            class="img-fluid"
                            v-if="trade.lti_vehicle_image"
                            v-on:click.stop.prevent="trade_vehicleDetail(trade)"
                            :src="trade.lti_vehicle_image"
                          />
                          <img
                            class="img-fluid"
                            v-else
                            v-on:click.stop.prevent="trade_vehicleDetail(trade)"
                            src="../../assets/img/carplaceholder.png"
                          />
                          <!-- <img class="img-fluid"   src="../../assets/img/Rectangle 99.svg" alt=""> -->
                        </div>
                        <div class="vehicle-table">
                          <h4>
                            {{ trade.lti_vehicle_make }}
                            {{ trade.lti_vehicle_model }}
                            {{ trade.lti_vehicle_year }}
                          </h4>
                          <table class="table">
                            <tbody>
                              <tr>
                                <td>VIN</td>
                                <td>{{ trade.lti_vehicle_vin }}</td>
                              </tr>
                              <tr>
                                <td>Miles</td>
                                <td>{{ trade.lti_vehicle_mileage }}</td>
                              </tr>
                              <tr>
                                <td>Trim</td>
                                <td>{{ trade.lti_vehicle_trim_style }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </slide>
                      <!-- detais-title -->
                    </carousel>
                  </div>
                </div>
                <!-- Vehicle-tab -->

                <!-- Add vehicle Modal -->
                <div
                  class="modal fade"
                  id="addVehicle"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="Add Trade"
                  aria-hidden="true"
                >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          Add Vehicle
                        </h5>
                        <a
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true"
                            ><img
                              class="img-fluid"
                              src="../../assets/img/23. Closex.svg"
                              alt=""
                          /></span>
                        </a>
                      </div>
                      <form v-on:submit.prevent="addVehicle">
                        <div class="modal-body">
                          <div class="add-member add-trades">
                            <!-- <div class="form-group row mb-4">
                                                        <label class="col-sm-12 col-form-label">VIN</label>
                                                        <div class="col-sm-8">
                                                            <input type="text" class="form-control"  v-model="vehicle.v_vin">
                                                        </div>
                                                        <div class="col-sm-4">
                                                            <a v-on:click.stop.prevent="vinDecode('vehicle')" :disabled="btnLoader" href="#" class="rem-btn" style="width: 100%;"><span v-if="btnLoader" style="width: 100%;"><span class="spinner-grow spinner-grow-sm"></span> Wait...</span><span style="width: 100%;text-align:center" v-if="!btnLoader"> Decode</span></a>
                                                        </div>

                                                    </div> -->

                            <div class="form-row">
                              <!-- <div class="form-group col-md-4">
                                                            <label for="t_years">Stock # <span>*</span></label>
                                                            <input type="text" class="form-control" id="t_years"  v-model="vehicle.v_stock_no">
                                                        </div> -->
                              <div class="form-group col-md-4">
                                <label for="t_years">Type <span>*</span></label>
                                <select
                                  class="form-control"
                                  required
                                  v-model="vehicle.type"
                                >
                                  <option value="Used">Used</option>
                                  <option value="NULL">New</option>
                                </select>
                              </div>
                              <div class="form-group col-md-4">
                                <label for="t_years">Year</label>
                                <input
                                  type="number"
                                  class="form-control"
                                  id="t_years"
                                  v-model="vehicle.v_year"
                                />
                              </div>
                              <div class="form-group col-md-4">
                                <label>Make <span>*</span></label>
                                <input
                                  type="text"
                                  class="form-control"
                                  required
                                  v-model="vehicle.v_make"
                                />
                              </div>
                              <div class="form-group col-md-4">
                                <label>Model <span>*</span></label>
                                <input
                                  type="text"
                                  class="form-control"
                                  required
                                  v-model="vehicle.v_model"
                                />
                              </div>
                              <div class="form-group col-md-4">
                                <label>Trim</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="vehicle.trim"
                                />
                              </div>

                              <div class="form-group col-md-4">
                                <label>Style</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="vehicle.color"
                                />
                              </div>
                              <div class="form-group col-md-4">
                                <label>Mileage</label>
                                <input
                                  type="number"
                                  class="form-control"
                                  v-model="vehicle.v_mileage"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <a
                            href="#"
                            data-dismiss="modal"
                            aria-label="Close"
                            class="add-btn"
                            >Cancel</a
                          >
                          <button type="submit" class="rem-btn">
                            Add Vehicle
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <!-- Add Vehicle Modal -->

                <!-- Add Trade Modal -->
                <div
                  class="modal fade"
                  id="addTradeModal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="Add Trade"
                  aria-hidden="true"
                >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          Add Trade
                        </h5>
                        <a
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true"
                            ><img
                              class="img-fluid"
                              src="../../assets/img/23. Closex.svg"
                              alt=""
                          /></span>
                        </a>
                      </div>
                      <form
                        v-on:submit.prevent="addTrade"
                        enctype="multipart/form-data"
                      >
                        <div class="modal-body">
                          <div class="add-member add-trades">
                            <div class="form-group row mb-4">
                              <label class="col-sm-12 col-form-label"
                                >VIN</label
                              >
                              <div class="col-sm-8">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="trade_.vin"
                                />
                              </div>
                              <div class="col-sm-4">
                                <a
                                  v-on:click.stop.prevent="vinDecode('trade')"
                                  :disabled="btnLoader"
                                  href="#"
                                  class="rem-btn"
                                  style="width: 100%"
                                  ><span v-if="btnLoader" style="width: 100%"
                                    ><span
                                      class="spinner-grow spinner-grow-sm"
                                    ></span>
                                    Wait...</span
                                  ><span
                                    style="width: 100%; text-align: center"
                                    v-if="!btnLoader"
                                  >
                                    Decode</span
                                  ></a
                                >
                              </div>
                            </div>

                            <div class="form-row">
                              <div class="form-group col-md-4">
                                <label for="t_years"
                                  >Years <span>*</span></label
                                >
                                <input
                                  type="text"
                                  class="form-control"
                                  id="t_years"
                                  v-model="trade_.years"
                                />
                              </div>
                              <div class="form-group col-md-4">
                                <label>Make <span>*</span></label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="trade_.make"
                                />
                              </div>
                              <div class="form-group col-md-4">
                                <label>Model</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="trade_.model"
                                />
                              </div>
                              <div class="form-group col-md-4">
                                <label>Trim / Style</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="trade_.style"
                                />
                              </div>
                              <div class="form-group col-md-4">
                                <label>Color</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="trade_.color"
                                />
                              </div>
                              <div class="form-group col-md-4">
                                <label>Mileage <span>*</span></label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="trade_.mileage"
                                  required
                                />
                              </div>
                              <div class="form-group col-md-4">
                                <label>ACV</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="trade_.acv"
                                />
                              </div>
                              <div class="form-group col-md-4">
                                <label>Allowance</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="trade_.allowance"
                                />
                              </div>
                            </div>
                            <div class="form-group">
                              <label>Notes</label>
                              <textarea
                                class="form-control"
                                rows="2"
                                v-model="trade_.notes"
                              ></textarea>
                            </div>
                            <div class="form-group">
                              <label>Payoff</label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="trade_.payoff"
                              />
                            </div>

                            <div class="form-group">
                              <div class="send-btns attach">
                                <div class="button-wrapper">
                                  <span class="label">
                                    <img
                                      class="img-fluid"
                                      src="../../assets/img/aad.svg"
                                      alt=""
                                    />
                                    attached file </span
                                  ><input
                                    type="file"
                                    name="upload"
                                    id="upload"
                                    class="upload-box"
                                    placeholder="Upload File"
                                    v-on:change="onFileChange"
                                  />
                                </div>
                              </div>
                              <div>
                                <img
                                  style="
                                    width: 92px;
                                    position: relative;
                                    left: 239px;
                                  "
                                  v-if="url"
                                  :src="url"
                                />
                              </div>
                            </div>
                            <br /><br />
                          </div>
                        </div>
                        <div class="modal-footer">
                          <a
                            href="#"
                            data-dismiss="modal"
                            aria-label="Close"
                            class="add-btn"
                            >Cancel</a
                          >
                          <button
                            :disabled="btnLoader"
                            type="submit"
                            class="rem-btn"
                          >
                            <span v-if="btnLoader"
                              ><span
                                class="spinner-grow spinner-grow-sm"
                              ></span>
                              Loading...</span
                            >
                            <span v-if="!btnLoader"> Save trade</span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <!-- Add Trade Modal -->

                <!-- Update Trade Modal -->
                <div
                  class="modal fade"
                  id="trade_vehicleDetailModal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="Add Trade"
                  aria-hidden="true"
                >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          Update Trade
                        </h5>
                        <a
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true"
                            ><img
                              class="img-fluid"
                              src="../../assets/img/23. Closex.svg"
                              alt=""
                          /></span>
                        </a>
                      </div>
                      <form
                        v-on:submit.prevent="updateTrade"
                        enctype="multipart/form-data"
                      >
                        <div class="modal-body">
                          <div class="add-member add-trades">
                            <div class="form-row">
                              <div class="form-group col-md-4">
                                <label for="ut_years"
                                  >Years <span>*</span></label
                                >
                                <input
                                  type="text"
                                  class="form-control"
                                  id="ut_years"
                                  v-model="trade_.years"
                                />
                              </div>
                              <div class="form-group col-md-4">
                                <label>Make <span>*</span></label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="trade_.make"
                                />
                              </div>
                              <div class="form-group col-md-4">
                                <label>Model</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="trade_.model"
                                />
                              </div>
                              <div class="form-group col-md-4">
                                <label>Trim / Style</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="trade_.style"
                                />
                              </div>
                              <div class="form-group col-md-4">
                                <label>Color</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="trade_.color"
                                />
                              </div>
                              <div class="form-group col-md-4">
                                <label>VIN</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="trade_.vin"
                                />
                              </div>
                              <div class="form-group col-md-4">
                                <label>Mileage</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="trade_.mileage"
                                />
                              </div>
                              <div class="form-group col-md-4">
                                <label>ACV</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="trade_.acv"
                                />
                              </div>
                              <div class="form-group col-md-4">
                                <label>Allowance</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="trade_.allowance"
                                />
                              </div>
                            </div>
                            <div class="form-group">
                              <label>Notes</label>
                              <textarea
                                class="form-control"
                                rows="2"
                                v-model="trade_.notes"
                              ></textarea>
                            </div>
                            <div class="form-group">
                              <label>Payoff</label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="trade_.payoff"
                              />
                            </div>
                            <div class="form-group">
                              <div class="send-btns attach">
                                <div class="button-wrapper">
                                  <span class="label">
                                    <img
                                      class="img-fluid"
                                      src="../../assets/img/aad.svg"
                                      alt=""
                                    />
                                    attached file </span
                                  ><input
                                    type="file"
                                    name="upload"
                                    id="upload"
                                    class="upload-box"
                                    placeholder="Upload File"
                                    v-on:change="onFileChange"
                                  />
                                </div>
                              </div>
                              <div>
                                <img
                                  style="
                                    width: 92px;
                                    position: relative;
                                    left: 239px;
                                  "
                                  v-if="url"
                                  :src="url"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <a @click="deleteTrade(trade_id)" class="rem-btn"
                            >Delete</a
                          >
                          <a
                            href="#"
                            data-dismiss="modal"
                            aria-label="Close"
                            class="add-btn"
                            >Cancel</a
                          >
                          <button
                            :disabled="btnLoader"
                            type="submit"
                            class="rem-btn"
                          >
                            <span v-if="btnLoader"
                              ><span
                                class="spinner-grow spinner-grow-sm"
                              ></span>
                              Loading...</span
                            >
                            <span v-if="!btnLoader"> Update trade</span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <!-- Update Trade Modal -->

                <!-- car deatils Modal -->
                <div
                  class="modal fade"
                  id="vehicleDetailModal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="vehicleDetailModalLabel"
                  aria-hidden="true"
                >
                  <div
                    class="modal-dialog modal-dialog-centered modal-lg"
                    role="document"
                  >
                    <div class="modal-content">
                      <a
                        class="delas-colse close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true"
                          ><img
                            class="img-fluid"
                            src="../../assets/img/23. Closex.svg"
                            alt=""
                        /></span>
                      </a>
                      <div class="modal-body Intersets-modal">
                        <div class="row">
                          <div class="col-lg-7">
                            <div class="exzoom" id="exzoom">
                              <div class="exzoom_img_box">
                                <ul class="exzoom_img_ul">
                                  <li>
                                    <img
                                      class="img-fluid car"
                                      :src="v_detail.v_image"
                                      alt=""
                                    />
                                  </li>
                                </ul>
                              </div>
                              <!-- <div class="exzoom_nav"></div>
                                                        <p class="exzoom_btn">
                                                            <a href="javascript:void(0);" class="exzoom_prev_btn"> </a>
                                                            <a href="javascript:void(0);" class="exzoom_next_btn"> > </a>
                                                        </p> -->
                            </div>
                            <!-- <div class="notes-con">
                                                        <h3>Note</h3>
                                                        <input type="text" class="form-control" placeholder="Customer called and setup  appointments" />
                                                    </div>
                                                    <div class="notes-con">
                                                        <h3>Notes</h3>
                                                        <input type="text" class="form-control" placeholder="This car was diver" />
                                                    </div> -->
                          </div>
                          <div class="col-lg-5">
                            <div class="details-modal">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <td colspan="2">
                                      <h5 style="color: #000">
                                        {{ v_detail.v_year }}
                                        {{ v_detail.v_make }}
                                        {{ v_detail.v_model }}
                                      </h5>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="td">Stock#</td>
                                    <td>{{ v_detail.v_stock_no }}</td>
                                  </tr>
                                  <tr>
                                    <td class="td">Mileage</td>
                                    <td>
                                      {{ v_detail.v_mileage | number("0,0") }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="td">Color</td>
                                    <td>
                                      <!--<span class="black"></span>-->{{
                                        v_detail.v_ext_color
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="td">Ext</td>
                                    <td>{{ v_detail.v_ext_color }}</td>
                                  </tr>
                                  <tr>
                                    <td class="td">Interior</td>
                                    <td>{{ v_detail.v_int_color }}</td>
                                  </tr>
                                  <tr>
                                    <td class="td">Engine</td>
                                    <td>{{ v_detail.v_engine }}</td>
                                  </tr>

                                  <tr>
                                    <td class="td">MSRP</td>
                                    <td>
                                      {{ v_detail.v_msrp | currency }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td class="td">Selling Price</td>
                                    <td>
                                      {{ v_detail.v_price | currency }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td class="td">Special Price</td>
                                    <td v-if="v_detail.v_special_price">
                                      ${{ v_detail.v_special_price | currency }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <!-- People Are Intersted -->
                            <!-- <div class="People-Intersted">
                                                        <h3>People Are Intersted</h3>
                                                        <div class="inter-people">
                                                            <ul>
                                                                <li>
                                                                    <a href="#"><img class="img-fluid" src="assets/img/inrerest3.svg" alt="" /></a>
                                                                </li>
                                                                <li>
                                                                    <a href="#"><img class="img-fluid" src="assets/img/inrerest2.svg" alt="" /></a>
                                                                </li>
                                                                <li>
                                                                    <a href="#"><img class="img-fluid" src="assets/img/inrerest1.svg" alt="" /></a>
                                                                </li>
                                                            </ul>
                                                            <p>20 People <a href="#" data-toggle="modal" data-target="#exampleModal22">Show more</a></p>
                                                        </div>
                                                    </div> -->
                            <!-- People Are Intersted -->
                          </div>
                          <div class="col-12">
                            <div
                              class="notes-con"
                              v-if="v_detail.v_description"
                            >
                              <h3>Description</h3>
                              <p v-html="v_detail.v_description"></p>
                            </div>
                          </div>
                          <div class="col-12 text-center mt-3">
                            <button
                              class="rem-btn"
                              style="display: initial"
                              v-on:click="updateVOI(v_detail)"
                            >
                              Select
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- car deatils Modal -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Vehicle of Interest -->
  </div>
</template>

<script>
import axios from "axios";
import VueDaval from "vue-daval";
import $ from "jquery";
import { Carousel, Slide } from "vue-carousel";
import Autocomplete from "vuejs-auto-complete";
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";

export default {
  name: "VehicleOfInterest",
  props: ["lead_detail"],
  data() {
    return {
      url: "",
      file: "",
      trade_: {
        years: "",
        make: "",
        model: "",
        mileage: "",
        color: "",
        style: "",
        acv: "",
      },
      trades: {},
      voi: {},
      similarVehicles: [],
      leads: [],
      v_detail: {},
      trade_id: "",
      btnLoader: false,
      vehicle: {},
      novehicleresult: 0,
    };
  },
  components: {
    Carousel,
    Slide,
    Autocomplete,
    simplebar,
  },
  mixins: [VueDaval],
  vdRules: {
    trade_: {
      years: { required: true },
      make: { required: true },
      //tMin:{required: true}, tDtype: {required: true},
      //desc:{required: true}
    },
  },
  computed:{
    IsV2(){
      if(this.$storage.get('auth').user){
        if(this.$storage.get('auth').user.dealer_autolink_version){
          if(this.$storage.get('auth').user.dealer_autolink_version == 'v2'){
            return true;
          }
        }
      }
      return false;
    },
    IsV2TokenExist(){
      if(this.$storage.get('auth').user){
        if(this.$storage.get('auth').user.dealer_autolink_v2_auth_token){
          if(this.$storage.get('auth').user.dealer_autolink_v2_auth_token != ''){
            return true;
          }
        }
      }
      return false;
    },
    V2AuthHeader(){
      if(this.$storage.get('auth').user){
        if(this.$storage.get('auth').user.dealer_autolink_v2_auth_token){
          if(this.$storage.get('auth').user.dealer_autolink_v2_auth_token != ''){
            return {
              'Authorization' : 'Bearer ' + this.$storage.get('auth').user.dealer_autolink_v2_auth_token
            };
          }
        }
      }
      return {};
    }
  },
  mounted() {
    $(".autocomplete__results").on(
      "click",
      ".autocomplete_no_results",
      function () {
        $("#addVehicle").modal("show");
        $(".autocomplete_no_results").remove();
      }
    );
    let v = this;
    $('a[href="#Trades"]').on("shown.bs.tab", function () {
      setTimeout(() => {
        v.getTrades();
      });
    });
  },

  created() {
    //this.getTrades();
    this.getVOI();
  },
  methods: {
    onFileChange(e) {
      this.file = e.target.files[0];
      this.url = URL.createObjectURL(this.file);
      console.log(this.url);
    },
    addVehicle() {
      axios
        .post(
          "add/vehicle",
          { vehicle: this.vehicle, lead_id: this.$route.params.id },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.status) {
            this.getVOI();
            $("#addVehicle").modal("hide");
            $(".autocomplete_no_results").remove();
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // this.updateVOI(this.vehicle);
    },
    addVehicleOnNoResult() {
      if ($(".autocomplete__results .autocomplete_no_results").length == 0) {
        $(".autocomplete__results").append(
          '<li class="autocomplete__results__item autocomplete_no_results">Add Vehicle</li>'
        );
      }
    },
    clear() {
      $(".autocomplete_no_results").remove();
    },

    vinDecode(type) {
      if (
        (this.trade_.vin != "" &&
          this.trade_.vin != undefined &&
          this.trade_.vin != "undefined" &&
          this.trade_.vin != null) ||
        (this.vehicle.v_vin != "" &&
          this.vehicle.v_vin != undefined &&
          this.vehicle.v_vin != "undefined" &&
          this.vehicle.v_vin != null)
      ) {
        this.btnLoader = true;
        if (type == "trade") {
          axios
            .get(
              "https://autolinkme.com/webservices/crm/vin_decoder?id=" +
                this.$storage.get("auth").user.dealer_id +
                "&status=active&vin=" +
                this.trade_.vin
            )
            .then((res) => {
              if (res.data.status) {
                let v_data = res.data.data;
                this.trade_.years = v_data.Year;
                this.trade_.make = v_data.Make;
                this.trade_.model = v_data.Model;
                //this.trade_.mileage = : v_data.Year
                //this.trade_.color = : v_data.Year
                this.trade_.style = v_data.Trim;

                console.log(res.data.data);
              }
              this.btnLoader = false;
            })
            .catch((err) => {
              console.log(err);
              this.btnLoader = false;
            });
        } else if (type == "vehicle") {
          axios
            .get(
              "https://autolinkme.com/webservices/crm/vin_decoder?id=" +
                this.$storage.get("auth").user.dealer_id +
                "&status=active&vin=" +
                this.vehicle.v_vin
            )
            .then((res) => {
              if (res.data.status) {
                let v_data = res.data.data;
                this.vehicle.v_year = v_data.Year;
                this.vehicle.v_make = v_data.Make;
                this.vehicle.v_model = v_data.Model;
                //this.trade_.mileage = : v_data.Year
                //this.trade_.color = : v_data.Year
                this.vehicle.style = v_data.Trim;

                console.log(res.data.data);
              }
              this.btnLoader = false;
            })
            .catch((err) => {
              console.log(err);
              this.btnLoader = false;
            });
        }
      }
    },
    deleteTrade(id) {
      axios
        .get("/delete/trade/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.$root.$emit("action", "new_activity");
            $("#trade_vehicleDetailModal").modal("hide");
            this.getTrades();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateVOI(veh) {
      //console.log(veh)
      if (veh.v_id == null) {
        this.$toast.open({
          position: "top-right",
          message: "Vehicle is not selected",
          type: "info",
        });
        return false;
      } else {
        axios
          .post("update_voi/" + this.$route.params.id, veh, {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          })
          .then((res) => {
            if (res.data.success) {
              this.getVOI();
              $("#vehicleDetailModal").modal("hide");
              this.$root.$emit("action", "new_activity");
              this.$root.$emit("action", "update_voi", res.data.vid);
              this.lead_detail.l_vehicle_id = res.data.vid;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    customerLeads(c_id) {
      axios
        .post(
          "customer_leads",
          { lead_id: this.$route.params.id, customer_id: c_id, type: "all" },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            const data = res.data;
            //console.log(data.success)
            this.leads = data.success;
            this.$root.$emit("action", "customerLeads", this.leads);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    vehicleDetail(v) {
      //console.log(v)
      this.v_detail = {};
      if (
        v.vid != undefined &&
        v.vid != "undefined" &&
        v.vid != null &&
        v.vid != ""
      ) {
        this.v_detail = {};
        this.v_detail.v_stock_no = v.stock_no;
        this.v_detail.v_mileage = v.mileage;
        this.v_detail.v_ext_color = v.ext_color;
        this.v_detail.v_int_color = v.int_color;
        this.v_detail.v_price = v.price;
        this.v_detail.v_engine = v.engine;
        this.v_detail.v_description = v.description;
        this.v_detail.v_image = v.img;
        this.v_detail.v_id = v.id;
        this.v_detail.v_vin = v.vin;
        this.v_detail.v_year = v.year;
        this.v_detail.v_make = v.make;
        this.v_detail.v_model = v.model;
        this.v_detail.v_msrp = v.v_msrp;
        this.v_detail.v_special_price = v.v_special_price;
      } else {
        this.v_detail = v;
      }

      $("#vehicleDetailModal").modal("show");
    },
    trade_vehicleDetail(trade) {
      this.trade_ = {};
      this.trade_.years = trade.lti_vehicle_year;
      this.trade_.make = trade.lti_vehicle_make;
      this.trade_.vin = trade.lti_vehicle_vin;
      this.trade_.model = trade.lti_vehicle_model;
      this.trade_.style = trade.lti_vehicle_trim_style;
      this.trade_.color = trade.lti_vehicle_color;
      this.trade_.mileage = trade.lti_vehicle_mileage;
      this.trade_.acv = trade.lti_vehicle_acv;
      this.trade_.allowance = trade.lti_vehicle_allowance;
      this.trade_.notes = trade.lti_vehicle_notes;
      this.trade_.payoff = trade.lti_vehicle_payoff;
      this.trade_id = trade.lti_id;
      this.url = trade.lti_vehicle_image;
      $("#trade_vehicleDetailModal").modal("show");
    },
    getTrades() {
      axios
        .get("tradeins/" + this.$route.params.id)
        .then((res) => {
          if (res.data.success) {
            const data = res.data;
            this.trades = data.success;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getVOI() {
      axios
        .get("voi/" + this.$route.params.id)
        .then((res) => {
          if (res.data.success) {
            const data = res.data;
            this.voi = data.success;
            if (this.voi.v_id) {
              this.$store.commit("updateVehicle", true);
              this.$store.commit("updatevehicleid", this.voi.v_id);
            }
            if (this.voi) {
              this.getSimilarVehicles(
                this.voi.v_make,
                this.voi.v_year,
                this.voi.v_model
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addTrade() {
      this.btnLoader = true;
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("acv", this.trade_.acv);
      formData.append("allowance", this.trade_.allowance);
      formData.append("color", this.trade_.color);
      formData.append("lead_id", this.$route.params.id);
      formData.append("make", this.trade_.make);
      formData.append("mileage", this.trade_.mileage);
      formData.append("model", this.trade_.model);
      formData.append("notes", this.trade_.notes);
      formData.append("payoff", this.trade_.payoff);
      formData.append("style", this.trade_.style);
      formData.append("vin", this.trade_.vin);
      formData.append("years", this.trade_.years);

      this.trade_.lead_id = this.$route.params.id;
      this.$vd.trade_
        .$validate()
        .then(() => {
          axios
            .post("add_trade", formData, {
              headers: {
                Authorization: "Bearer " + this.$storage.get("auth").token,
              },
            })
            .then((res) => {
              if (res.data.success) {
                this.$root.$emit("action", "new_activity");
                this.btnLoader = false;
                $("#addTradeModal").modal("hide");
                this.trade_ = {};
                this.getTrades();
              }
            })
            .catch((err) => {
              this.btnLoader = false;
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateTrade() {
      this.btnLoader = true;
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("acv", this.trade_.acv);
      formData.append("allowance", this.trade_.allowance);
      formData.append("color", this.trade_.color);
      formData.append("lead_id", this.$route.params.id);
      formData.append("make", this.trade_.make);
      formData.append("mileage", this.trade_.mileage);
      formData.append("model", this.trade_.model);
      formData.append("notes", this.trade_.notes);
      formData.append("payoff", this.trade_.payoff);
      formData.append("style", this.trade_.style);
      formData.append("vin", this.trade_.vin);
      formData.append("years", this.trade_.years);
      this.trade_.lead_id = this.$route.params.id;
      this.trade_.id = this.trade_id;
      axios
        .post("update/trade", formData, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.btnLoader = false;
            $("#trade_vehicleDetailModal").modal("hide");
            this.getTrades();
          }
        })
        .catch((err) => {
          this.btnLoader = false;
          console.log(err);
        });
    },
    getSimilarVehicles(make = "", year = "", model = "") {
      axios
        .get(
          "https://autolinkme.com/webservices/crm/inventory?id=" +
            this.$storage.get("auth").user.dealer_autolink_id +
            "&limit=5&offset=0&status=active&search_by=value&make=" +
            make +
            "&model=" +
            model +
            "&year=" +
            year
        )
        .then((res) => {
          if (res.data.status) {
            let sv = res.data.data;
            if (sv.length > 0) {
              let vm = this;
              sv.forEach(function (value, key) {
                //console.log(value)
                if (
                  value.vin != vm.voi.v_vin &&
                  value.imagelist != "" &&
                  value.imagelist != undefined &&
                  value.imagelist != null &&
                  value.imagelist != "undefined"
                ) {
                  var imgs = value.imagelist.split(",");
                  vm.similarVehicles = [];
                  vm.similarVehicles.push({
                    id: key,
                    vid: value.id,
                    vin: value.vin,
                    img: imgs[0],
                    year: value.year,
                    make: value.make,
                    model: value.model,
                    stock_no: value.stock_no,
                    mileage: value.mileage,
                    ext_color: value.ext_color,
                    int_color: value.int_color,
                    price: value.price,
                    engine: value.engine,
                    description: value.description,
                  });
                }
                //console.log(vm.similarVehicles)
                //console.log(key)
              });
            }
            //this.similarVehicles = res.data.data
            //console.log(res.data.data)
          } else {
            //alert("Invalid Trad In VIN number")
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formattedAutocompleteDisplay(result) {
      return (
        result.year +
        " " +
        result.make +
        " " +
        result.model +
        " - " +
        result.vin
      );
    },
    selectedVehicle(result) {
      //console.log(result.selectedObject)
      this.v_detail = {};
      var v = result.selectedObject;
      this.v_detail.v_stock_no = v.stock_no;
      this.v_detail.v_mileage = v.mileage;
      this.v_detail.v_ext_color = v.ext_color;
      this.v_detail.v_int_color = v.int_color;
      this.v_detail.v_price = v.price;
      this.v_detail.v_engine = v.engine;
      this.v_detail.v_description = v.description;
      this.v_detail.v_id = v.id;
      this.v_detail.v_vin = v.vin;
      this.v_detail.v_year = v.year;
      this.v_detail.v_make = v.make;
      this.v_detail.v_model = v.model;

      if (
        v.imagelist != "" &&
        v.imagelist != undefined &&
        v.imagelist != null &&
        v.imagelist != "undefined"
      ) {
        var imgs = v.imagelist.split(",");
        this.v_detail.v_image = imgs[0];
      }

      $("#vehicleDetailModal").modal("show");
    },
  },
  watch: {
    lead_detail: function (newVal) {
      //console.log(newVal)
      this.customerLeads(newVal.l_customer_id);
      // this.current_status = newVal
      // if (newVal == 6 || newVal == 7) {
      //     this.visit = 'desk'
      // }else{
      //     this.visit = newVal
      // }
    },
  },
};
</script>


<style>
.detais-search .autocomplete .autocomplete__inputs input {
  padding-left: 5px !important;
}
.car {
  width: 100%;
}
.td {
  width: 80px;
}
</style>
